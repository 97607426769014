import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import Typography from "@material-ui/core/Typography"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"

import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import UserContext from "../../utils/user/UserContext"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import Alert from "@material-ui/lab/Alert"
import Link from "@material-ui/core/Link"
import TypographyBold from "../../components/TypographyBold"
import MapIcon from "@material-ui/icons/Map"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import LanguageIcon from "@material-ui/icons/Language"
import WebIcon from "@material-ui/icons/Web"

const inicialValue = {
  nombre: "",
  nombreFantasia: "",
  idCiudad: 0,
  idBarrio: 0,
  callePrincipal: "",
  calleSecundaria: "",
  ubicacion: "",
  numeroContacto: "",
  TipoCliente: "",
  idCreate: 0,
}

export default function DetalleOferta() {
  const history = useHistory()
  const dataProps = history.location.state
  const classes = useStyles()
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)

  const [cliente, setCliente] = useState(inicialValue)

  useEffect(() => {}, [])

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Card className={classes.root}>
                <CardMedia
                  width="30%"
                  title={dataProps?.descripcion}
                  height="30%"
                  component="img"
                  src={dataProps?.urlFotoOferta}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item xs={12} sm={12} md={5} lg={5} xl={12}>
                  <Typography variant="body2" display="block" color="textSecondary">
                    Nombre
                  </Typography>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary"
                  >
                    {dataProps?.nombre}
                  </TypographyBold>
                </Grid>

                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <Typography variant="body2" display="block" color="textSecondary">
                    Descripción
                  </Typography>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary"
                  >
                    {dataProps?.descripcion}
                  </TypographyBold>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body2" display="block" color="textSecondary">
                    Ofrecido por
                  </Typography>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary"
                  >
                    {dataProps?.nombreMercado}
                  </TypographyBold>
                </Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Typography variant="body2" display="block" color="textSecondary">
                    Contacto
                  </Typography>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary"
                  >
                    {dataProps?.nroContacto}
                  </TypographyBold>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Alert
                    icon={<WhatsAppIcon fontSize="inherit" />}
                    variant="standard"
                    severity="success"
                    style={{
                      justifyContent: "center",
                      padding: "0px 10px 0px 10px",
                    }}
                  >
                    {" "}
                    <Link
                      target="_blank"
                      href={`https://wa.me/${dataProps?.whatsapp}?text=Me interesa la oferta de ${dataProps?.nombre}`}
                      color="inherit"
                    >
                      {" "}
                      {dataProps?.whatsapp}{" "}
                    </Link>{" "}
                  </Alert>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Link
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${dataProps?.latitud},${dataProps?.longitud}`}
                    color="inherit"
                  >
                    {" "}
                    <Alert
                      icon={<MapIcon fontSize="inherit" />}
                      variant="standard"
                      severity="error"
                      style={{
                        justifyContent: "center",
                        padding: "0px 10px 0px 10px",
                      }}
                    >
                      Ver Mapa{" "}
                    </Alert>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body2" display="block" color="textSecondary">
                    Categoría
                  </Typography>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary"
                  >
                    {dataProps?.categoria ? dataProps?.categoria : "Sin categoria "}
                  </TypographyBold>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body2" display="block" color="textSecondary">
                    Fecha desde
                  </Typography>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary"
                  >
                    {dataProps?.fechaDesde ? dataProps?.fechaDesde : "Sin Fecha"}
                  </TypographyBold>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body2" display="block" color="textSecondary">
                    Fecha hasta
                  </Typography>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary"
                  >
                    {dataProps?.fechaHasta ? dataProps?.fechaHasta : "Sin Fecha "}
                  </TypographyBold>
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Box p={1}>
                    <Link target="_blank" href={dataProps?.urlWeb} color="inherit">
                      {" "}
                      <Alert
                        icon={<LanguageIcon fontSize="inherit" />}
                        variant="standard"
                        severity="error"
                        style={{
                          justifyContent: "center",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        URL Sitio{" "}
                      </Alert>
                    </Link>
                  </Box>
                  <Box p={1}>
                    <Link target="_blank" href={dataProps?.facebook} color="inherit">
                      {" "}
                      <Alert
                        icon={<FacebookIcon fontSize="inherit" />}
                        variant="standard"
                        severity="info"
                        style={{
                          justifyContent: "center",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        facebook{" "}
                      </Alert>
                    </Link>
                  </Box>

                  <Box p={1}>
                    <Link
                      target="_blank"
                      href={dataProps?.instagram}
                      color="inherit"
                    >
                      {" "}
                      <Alert
                        icon={<InstagramIcon fontSize="inherit" />}
                        variant="standard"
                        severity="error"
                        style={{
                          justifyContent: "center",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        instagram{" "}
                      </Alert>
                    </Link>
                  </Box>

                  <Box p={1}>
                    <Link
                      target="_blank"
                      href={dataProps?.otrasRedes}
                      color="inherit"
                    >
                      {" "}
                      <Alert
                        icon={<WebIcon fontSize="inherit" />}
                        variant="standard"
                        severity="error"
                        style={{
                          justifyContent: "center",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        Otras Redes{" "}
                      </Alert>
                    </Link>
                  </Box>
                  <Box p={1}>
                    <Alert
                      icon={false}
                      variant="filled"
                      severity="warning"
                      style={{ justifyContent: "center" }}
                    >
                      <TypographyBold variant="body1" display="block" color="black">
                        GS. {parseFloat(dataProps?.costo).toLocaleString("es")}
                      </TypographyBold>
                    </Alert>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{ paddingTop: 25 }}
                ></Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
