import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Chip } from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"
import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import Alert from "@material-ui/lab/Alert"
import EditIcon from "@material-ui/icons/Edit"

export default function ListarGrupoInformacion() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [tipoList, setTipoList] = useState([])

  useEffect(() => {
    getTipo()
  }, [])

  const getTipo = async () => {
    const url = "intereses/obtener-grupos-de-informacion"
    try {
      const responseTipo = await axios.get(url)
      const responseStatus = responseTipo.status
      if (responseStatus === 200) {
        setTipoList(responseTipo.data)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("/nuevo-tipo-grupo-informacion/")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "id_tp_usuario_crm",
      width: "1%",
      hidden: true,
    },
    {
      title: "Tipo",
      field: "tipo",
      width: "75%",
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => history.push("./editar-tipo-colaborador/", props.data)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Editar"
            variant="outlined"
            color="secondary"
          />
        </Box>
      </>
    )
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={tipoList}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              //editar,
              childrenAccions,
            }}
          />
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
