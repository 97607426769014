const localization = {
  body: {
    emptyDataSourceMessage: "No se encontraron registros para mostrar",
    addTooltip: "Agregar",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    filterRow: {
      filterTooltip: "Filtro",
    },
    editRow: {
      deleteText: "¿Esta seguro que desea eliminar el registro?",
      cancelTooltip: "Cancelar",
      saveTooltip: "Guardar",
    },
  },
  grouping: {
    placeholder: "Mover Titulos",
  },
  header: {
    actions: "",
  },
  pagination: {
    labelRowsSelect: "Registros",
    labelRowsPerPage: "Registros por página",
    firstAriaLabel: "Primera Página",
    firstTooltip: "Primera Página",
    previousAriaLabel: "Página Anterior",
    previousTooltip: "Página Anterior",
    nextAriaLabel: "Siguiente Página",
    nextTooltip: "Siguiente Página",
    lastAriaLabel: "Última Página",
    labelDisplayedRows: "{from}-{to} DE {count}",
  },
  toolbar: {
    addRemoveColumns: "Agregar o remover columnas",
    nRowsSelected: "fila(s) selecionadas",
    showColumnsTitle: "Mostrar Columnas",
    showColumnsAriaLabel: "Mostrar Columnas",
    exportTitle: "Exportar",
    exportAriaLabel: "Exportar",
    exportName: "Exportar como CSV",
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
  },
}

export default localization
