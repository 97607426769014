import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Chip } from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import Switch from "@material-ui/core/Switch"
import Tooltip from "@material-ui/core/Tooltip"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import CancelIcon from "@material-ui/icons/Cancel"
import RoomIcon from "@material-ui/icons/Room"
import ExploreOffIcon from "@material-ui/icons/ExploreOff"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"
import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import Alert from "@material-ui/lab/Alert"
import EditIcon from "@material-ui/icons/Edit"
import CheckIcon from "@material-ui/icons/Check"

export default function ListaMercado() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })

  useEffect(() => {
    getData()
  }, [])

  const getData = async (props) => {
    setIsLoading(true)
    let url = "/ofertas/mercado-listar"
    try {
      const response = await axios.post(url)
      let status = response.status
      if (status === 200) {
        const mercado = response.data
        setData({ ...data, content: mercado?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nuevo-mercado")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "idMercado",
      width: "1%",
      hidden: true,
    },
    {
      title: "Descripción",
      field: "nombre",
    },
    {
      title: "Estado",
      width: "10%",
      align: "center",
      render: (rowData) =>
        rowData.estado === "ACTIVO" ? (
          <Alert
            icon={false}
            variant="filled"
            severity="success"
            style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
          >
            {rowData.estado}
          </Alert>
        ) : (
          <Alert
            icon={false}
            variant="filled"
            severity="error"
            style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
          >
            {rowData.estado}
          </Alert>
        ),
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const eliminarTipoColaborador = async (props) => {
    setIsLoading(true)
    let url = "ofertas/mercado-inactivar"
    try {
      const response = await axios.post(url, {
        idMercado: props?.idMercado,
      })
      let status = response.status
      if (status === 200) {
        getData()
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres ${
        props.data?.estado === "ACTIVO" ? "inactivar" : "activar "
      } a ${props.data?.nombre}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminarTipoColaborador(props.data)
      }
    })
  }

  const childrenAccions = (props) => {
    return (
      <>
        {props?.data?.estado === "ACTIVO" ? (
          <>
            <Box pl={1} pr={1}>
              <Chip
                onClick={(e) => handleInactivar(e, props)}
                avatar={
                  <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                    <HighlightOffIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                  </Avatar>
                }
                label="Inactivar"
                variant="outlined"
                color="error"
              />
            </Box>
          </>
        ) : (
          <>
            <Box pl={1} pr={1}>
              <Chip
                onClick={(e) => handleInactivar(e, props)}
                avatar={
                  <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                    <CheckIcon fontSize="small" style={{ color: "#ffffff" }} />
                  </Avatar>
                }
                label="Activar"
                variant="outlined"
                color="primary"
              />
            </Box>
          </>
        )}

        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => history.push("./editar-mercado/", props.data)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Editar"
            variant="outlined"
            color="secondary"
          />
        </Box>
      </>
    )
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              //editar,
              childrenAccions,
            }}
          />
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
