import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Box, Avatar, Chip } from "@material-ui/core"
import { alertWarningError } from "../../components/Notificaciones"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import HistoryIcon from "@material-ui/icons/History"
import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"

export default function ListaClientesVisitas() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })
  const [state, setState] = useState({})

  useEffect(() => {
    getClientes()
  }, [])

  const getClientes = async (props) => {
    setIsLoading(true)
    let url = "visitas/historico-cabecera"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const clientes = response.data
        setData({ ...data, content: clientes?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("./nuevo")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "id_cliente",
      width: "1%",
      hidden: true,
    },
    {
      title: "Nombre",
      field: "nombre",
      //  width: "15%",
    },

    {
      title: "Tefefono",
      field: "nro_contacto",
      width: "15%",
    },

    {
      title: "Tipo cliente",
      field: "tipo_cliente",
      width: "11%",
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    showTitle: false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const childrenAccions = (props) => {
    return (
      <Box pl={1} pr={1}>
        <Chip
          onClick={() => {
            history.push("./historicos", props.data)
          }}
          avatar={
            <Avatar variant="circle" sx={{ bgcolor: "red" }}>
              <HistoryIcon fontSize="small" />
            </Avatar>
          }
          label="Historicos de visitas"
          variant="outlined"
          color="secondary"
        />
      </Box>
    )
  }
  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              childrenAccions,
            }}
          />
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
