import L from "leaflet"
import IconSelectedMap from "../assets/images/placeholder.svg"

const iconUserLocation = new L.Icon({
  iconUrl: IconSelectedMap,
  iconRetinaUrl: IconSelectedMap,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(50, 50),
})

export { iconUserLocation }
