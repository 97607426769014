import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Link from "@material-ui/core/Link"

import MoreVertIcon from "@material-ui/icons/Send"

import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        INVESDE
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default function Colaboradores() {
  const classes = useStyles()
  const history = useHistory()

  const envioFormulario = (formulario) => {
    history.push("./" + formulario)
  }

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Paper>
            <ListItem button onClick={() => envioFormulario("nuevo-colaborador/")}>
              <ListItemText secondary="Crear nuevo colaborador " />
              <MoreVertIcon color="action" />
            </ListItem>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Paper>
            <ListItem button onClick={() => envioFormulario("lista-colaboradores/")}>
              <ListItemText secondary="Listar colaboradores" />
              <MoreVertIcon color="action" />
            </ListItem>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Paper>
            <ListItem
              button
              onClick={() => envioFormulario("nuevo-tipo-colaborador/")}
            >
              <ListItemText secondary="Crear tipo de colaborador" />
              <MoreVertIcon color="action" />
            </ListItem>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Paper>
            <ListItem
              button
              onClick={() => envioFormulario("lista-tipo-colaborador/")}
            >
              <ListItemText secondary="Listar tipo de colaborador" />
              <MoreVertIcon color="action" />
            </ListItem>
          </Paper>
        </Grid>
      </Grid>
      <Box pb={2} className={classes.footerBotttom}>
        <Copyright />
      </Box>
    </>
  )
}
