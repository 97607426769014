import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Chip } from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import LinearProgress from "@material-ui/core/LinearProgress"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import { Grid, Box, Avatar } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import VisibilityIcon from "@material-ui/icons/Visibility"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

export default function ListaCarteras() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })
  const [isLoadingUsuario, setIsLoadingUsuario] = useState(false)
  const [usuarioList, setUsuarioList] = useState({ content: [] })
  const [cartera, setCartera] = useState({})
  const [open, setOpen] = useState(false)
  const [openLoad, setOpenLoad] = useState(false)

  useEffect(() => {
    getCartera()
    getColaboradores()
  }, [])

  const getCartera = async (props) => {
    setIsLoading(true)
    let url = "carteras/cartera-listar/"
    try {
      const response = await axios.post(url)
      let status = response.status
      if (status === 200) {
        const carteras = response.data
        setData({ ...data, content: carteras?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getColaboradores = async (props) => {
    setIsLoadingUsuario(true)
    let url = "visitas/visita-usuario"
    try {
      const response = await axios.post(url)
      let status = response.status
      if (status === 200) {
        const colaboradores = response.data
        setUsuarioList({ ...usuarioList, content: colaboradores?.result })

        setIsLoadingUsuario(false)
      }
    } catch (error) {
      setIsLoadingUsuario(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nueva-cartera/")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "idCartera",
      width: "1%",
      hidden: true,
    },

    {
      title: "Nombre",
      field: "cartera",
      //  width: "15%",
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const editar = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
  }

  const eliminarCartera = async (props) => {
    setIsLoading(true)
    let url = "carteras/cartera-eliminar"
    try {
      const response = await axios.post(url, {
        idCartera: props?.idCartera,
      })
      let status = response.status
      if (status === 200) {
        getCartera()
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar a ${props.data?.cartera}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminarCartera(props.data)
      }
    })
  }

  const handleDetalle = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
    history.push("./detalle/", props.data)
  }

  const handleAgregarUsuario = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
    setOpen(true)
    setCartera(props.data)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenLoad(false)
  }

  function AsignarUsuario(props) {
    const { open, onClose, data } = props
    const [usuario, setUsuario] = useState({})

    console.log(data)

    const onSelectUsuario = (e, value) => {
      if (value && value?.id !== usuario?.id) {
        setUsuario(value)
      }

      if (value === null) {
        setUsuario({})
      }
    }
    function Loaders() {
      return <LinearProgress />
    }

    const handleGuardar = async () => {
      onClose()
      let url = "carteras/agregar-usuario"

      let newObject = {
        idCartera: data?.idCartera,
        idUsuario: usuario?.id,
      }
      console.log(newObject)

      try {
        const response = await axios.post(url, newObject)
        let status = response.status
        if (status === 200) {
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.go(0)
        }
      } catch (error) {
        onClose()

        if (error.response) {
          alertWarningError(error.response)
        }
      }
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          AGREGAR USUARIO A LA CARTERA
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                CARTERA SELECCIONADA
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Chip
                  label={data?.cartera}
                  variant="default"
                  color="primary"
                  icon={<AssignmentIndIcon />}
                />
              </Box>
            </Grid>

            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                SELECCIONAR USUARIO
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Autocomplete
                  id="idUsuario"
                  onChange={onSelectUsuario}
                  size="small"
                  value={usuario || ""}
                  loading={isLoadingUsuario}
                  options={usuarioList.content}
                  getOptionLabel={(option) =>
                    option.id ? option?.nombre + " " + option?.apellido : ""
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="usuario"
                      label="Usuarios"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingUsuario ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={Object.keys(usuario).length === 0 ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </DialogActions>
        {openLoad && <Loaders />}
      </Dialog>
    )
  }

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circle">
                <VisibilityIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>

        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleAgregarUsuario(e, props)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <PersonAddIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Asignar usuario"
            variant="outlined"
            color="secondary"
          />
        </Box>

        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleInactivar(e, props)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <HighlightOffIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Inactivar"
            variant="outlined"
            color="error"
          />
        </Box>
      </>
    )
  }
  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,

              childrenAccions,
            }}
          />
          {open && (
            <AsignarUsuario open={open} onClose={handleClose} data={cartera} />
          )}
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
