import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Typography } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import InputLabel from "@material-ui/core/InputLabel"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"

import UserContext from "../../utils/user/UserContext"
import InputBase from "@material-ui/core/InputBase"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import Search from "@material-ui/icons/Search"
import BuscadorDirectorDialog from "../../components/BuscadorDirectorDialog"
import BuscadorClienteDialog from "../../components/BuscadorClienteDialog"
import swal from "sweetalert"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"

const inicialValue = {
  idColaborador: 0,
  idComercio: 0,
  fecha: "",
  hora: "",
  tipoVisita: "",
  comentario: "",
}

export default function NuevoRecorrido() {
  const history = useHistory()
  const classes = useStyles()
  const userContext = useContext(UserContext)
  const [isLoadingTipoVisita, setIsLoadingTipoVisita] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [visita, setVisita] = useState(inicialValue)
  const [openDirector, setOpenDirector] = useState(false)
  const [usuario, setUsuario] = useState({ id: "", nombre: "" })
  const [openCliente, setOpenCliente] = useState(false)
  const [cliente, setCliente] = useState({ id: "", nombre: "" })
  const [tipoVisita, setTipoVisita] = useState({})

  useEffect(() => {}, [])

  const handleChangeComentario = (event) => {
    let copyInput = {
      ...visita,
      comentario: event.target.value,
    }
    setVisita(copyInput)
  }

  const handleChangeFecha = (value) => {
    console.log(value.target.value)

    let copyInput = {
      ...visita,
      fecha: value.target.value,
    }
    setVisita(copyInput)
  }

  const handleChangeHora = (value) => {
    console.log(value.target.value)

    let copyInput = {
      ...visita,
      hora: value.target.value,
    }
    setVisita(copyInput)
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "visitas/visita-crear"

    try {
      const response = await axios.post(url, visita)
      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleDirector = (value) => {
    console.log(value)
    let copyInput = {
      ...visita,
      idColaborador: value.id,
    }
    setVisita(copyInput)
    setUsuario({
      ...usuario,
      id: value.id,
      nombre: value.nombre + " " + value.apellido,
    })
  }

  const handleCloseDirector = (value) => {
    setOpenDirector(false)
  }
  const handleOpenDirector = () => {
    setOpenDirector(true)
  }

  const onSelectTipoVisita = (e, value) => {
    if (value && value !== tipoVisita) {
      setTipoVisita(value)
      let copyInput = { ...visita, tipoVisita: value }
      setVisita(copyInput)
    }

    if (value === null) {
      setTipoVisita({})
      let copyInput = { ...visita, tipoVisita: "" }
      setVisita(copyInput)
    }
  }

  const handleCloseCliente = (value) => {
    setOpenCliente(false)
  }
  const handleOpenCliente = () => {
    setOpenCliente(true)
  }
  const handleCliente = (value) => {
    console.log(value)
    let copyInput = {
      ...visita,
      idComercio: value.id_cliente,
    }
    setVisita(copyInput)
    setCliente({ ...cliente, id: value.id_cliente, nombre: value.nombre })
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <InputLabel htmlFor="my-input" style={{ paddingLeft: 4 }}>
                Asignado a
              </InputLabel>

              <Paper className={classes.rootPaper}>
                <InputBase
                  className={classes.input}
                  id="responsable"
                  name="responsable"
                  readOnly
                  fullWidth
                  value={usuario.nombre}
                  //   onBlur={() => loadCliente()}
                  inputProps={{
                    "aria-label": "responsable",
                  }}
                />

                <IconButton
                  style={{
                    color: "#396b99",
                    textAlign: "center",
                    //  minWidth: 45,
                  }}
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => handleOpenDirector()}
                >
                  <Search />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item sm={12} md={6} lg={6} xl={6}>
              <InputLabel htmlFor="my-input" style={{ paddingLeft: 4 }}>
                Comercio a visitar
              </InputLabel>
              <Paper className={classes.rootPaper}>
                <InputBase
                  readOnly
                  className={classes.input}
                  id="ruc"
                  name="ruc"
                  //placeholder="Cliente"
                  fullWidth
                  //    onChange={(e) => handleChangeCliente(e)}
                  value={cliente.nombre}
                  //   onBlur={() => loadCliente()}
                  inputProps={{
                    "aria-label": "ruc",
                  }}
                />

                <IconButton
                  style={{
                    color: "#396b99",
                    textAlign: "center",
                    //  minWidth: 45,
                  }}
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => handleOpenCliente()}
                >
                  <Search />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item sm={6} md={3} lg={3} xl={3}>
              <TextField
                variant="outlined"
                id="fecha"
                name="fecha"
                label="Fecha a visitar"
                type="date"
                size="small"
                onChange={(e) => handleChangeFecha(e)}
                defaultValue={visita.fechaVisita}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item sm={6} md={3} lg={3} xl={3}>
              <TextField
                variant="outlined"
                id="hora"
                name="hora"
                label="Hora Visita"
                type="time"
                size="small"
                onChange={(e) => handleChangeHora(e)}
                defaultValue={visita.horaVisita}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item sm={6} md={3} lg={3} xl={3}>
              <Autocomplete
                id="tipoVisita"
                size="small"
                value={tipoVisita || ""}
                onChange={onSelectTipoVisita}
                options={["PRESENCIAL", "TELEFÓNICA", "VIRTUAL"]}
                getOptionLabel={(option) => (option ? option : "")}
                renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
                loading={isLoadingTipoVisita}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tipo Visita"
                    name="tipoVisita"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingTipoVisita ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={12} md={12} lg={12} xl={12}>
              <TextField
                variant="outlined"
                size="small"
                id="comentario"
                name="comentario"
                label="Comentario"
                type="text"
                className={classes.DialogoTexto}
                value={visita.comentario}
                onChange={(value) => handleChangeComentario(value)}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      {openDirector && (
        <BuscadorDirectorDialog
          open={openDirector}
          onClose={handleCloseDirector}
          onSetData={handleDirector}
          idUser={userContext.state.idUsuario}
        />
      )}

      {openCliente && (
        <BuscadorClienteDialog
          open={openCliente}
          onClose={handleCloseCliente}
          onSetData={handleCliente}
          idUser={userContext.state.idUsuario}
        />
      )}
    </>
  )
}
