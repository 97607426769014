import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Chip } from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import LinearProgress from "@material-ui/core/LinearProgress"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import { Grid, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import { red, orange, blue, green } from "@material-ui/core/colors"
import swal from "sweetalert"
import TypographyBold from "../../components/TypographyBold"
import TimerIcon from "@material-ui/icons/Timer"
import TodayIcon from "@material-ui/icons/Today"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import EventIcon from "@material-ui/icons/Event"
import EditIcon from "@material-ui/icons/Edit"
import CloseIcon from "@material-ui/icons/Close"

import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Avatar from "@material-ui/core/Avatar"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import HistoryIcon from "@material-ui/icons/History"
import CachedIcon from "@material-ui/icons/Cached"
import VisibilityIcon from "@material-ui/icons/Visibility"
import Alert from "@material-ui/lab/Alert"
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu"
import MotorcycleIcon from "@material-ui/icons/Motorcycle"

const initFiltro = {
  fechaDesde: null,
  fechaHasta: null,
  idComercio: null,
  idEstado: null,
}

export default function ListaPedidos() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    content: [],
  })
  const [isLoadingEstado, setIsLoadingEstado] = useState(false)
  const [isLoadingComercio, setIsLoadingComercio] = useState(false)
  const [quitarFitro, setQuitarFiltro] = useState(false)

  const [comercio, setComercio] = useState({})
  const [dashboard, setDashboard] = useState({})
  const [listComercio, setListComercio] = useState([])

  const [estado, setEstado] = useState({})
  const [listEstado, setListEstado] = useState([])
  const [filtro, setFiltro] = useState(initFiltro)

  useEffect(() => {
    getPedido()
    getDashboard()
    getComercio()
    getEstado()
  }, [])

  const getFiltro = async (props) => {
    setData({ ...data, content: [] })
    setIsLoading(true)
    setQuitarFiltro(true)

    let url = "pedidos/adm-pedido/"
    try {
      const response = await axios.post(url, filtro)
      let status = response.status
      if (status === 200) {
        const filtroResponse = response.data
        console.log(filtroResponse)
        setData({
          ...data,
          content: filtroResponse?.result,
        })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getPedido = async () => {
    setIsLoading(true)
    setQuitarFiltro(false)
    setFiltro(initFiltro)
    setComercio({})
    setEstado({})
    let url = "pedidos/adm-pedido/"
    try {
      const response = await axios.post(url, initFiltro)
      let status = response.status
      if (status === 200) {
        const pedidos = response.data
        setData({
          ...data,
          content: pedidos?.result,
        })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const getDashboard = async () => {
    setIsLoading(true)

    let url = "pedidos/adm-pedido-dashboard/"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const dataResponse = response.data
        setDashboard(dataResponse?.result[0])

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getComercio = async () => {
    setIsLoadingComercio(true)

    let url = "comercios/comercio-listar/0/ACTIVADO"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const dataResponse = response.data
        setListComercio(dataResponse?.result)

        setIsLoadingComercio(false)
      }
    } catch (error) {
      setIsLoadingComercio(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getEstado = async () => {
    setIsLoadingEstado(true)

    let url = "pedidos/estado-pedido/"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const dataResponse = response.data
        setListEstado(dataResponse?.result)

        setIsLoadingEstado(false)
      }
    } catch (error) {
      setIsLoadingEstado(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const onSelecComercio = (e, value) => {
    console.log(value)
    if (value && value !== comercio) {
      setComercio(value)
      let copyInput = { ...filtro, idComercio: value?.id_cliente }
      setFiltro(copyInput)
    }

    if (value === null) {
      setComercio({})
      let copyInput = { ...filtro, idComercio: 0 }
      setFiltro(copyInput)
    }
  }

  const onSelecEstado = (e, value) => {
    if (value && value !== estado) {
      setEstado(value)
      let copyInput = { ...filtro, idEstado: value?.idEstado }
      setFiltro(copyInput)
    }

    if (value === null) {
      setEstado({})
      let copyInput = { ...filtro, idEstado: 1 }
      setFiltro(copyInput)
    }
  }

  const handleFechaDesde = (event) => {
    if (event.target.value === "") {
      let copyInput = { ...filtro, fechaDesde: "listar" }
      setFiltro(copyInput)
    } else {
      let copyInput = { ...filtro, fechaDesde: event.target.value }
      setFiltro(copyInput)
    }
  }

  const handleFechaHasta = (event) => {
    if (event.target.value === "") {
      let copyInput = { ...filtro, fechaHasta: "listar" }
      setFiltro(copyInput)
    } else {
      let copyInput = { ...filtro, fechaHasta: event.target.value }
      setFiltro(copyInput)
    }
  }

  const title = (
    <>
      <Grid container spacing={2} style={{ padding: "15px 0px" }}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TextField
            variant="outlined"
            id="fecha"
            name="fecha"
            label="Desde"
            type="date"
            size="small"
            onChange={(e) => handleFechaDesde(e)}
            defaultValue={filtro.fechaDesde}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <TextField
            variant="outlined"
            id="fecha"
            name="fecha"
            label="Hasta"
            type="date"
            size="small"
            onChange={(e) => handleFechaHasta(e)}
            defaultValue={filtro.fechaHasta}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Autocomplete
            id="comercio"
            size="small"
            value={comercio || ""}
            onChange={onSelecComercio}
            options={listComercio}
            getOptionLabel={(option) =>
              option?.id_cliente ? option?.nomb_fantasia : ""
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Comercio"
                name="usuario"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Autocomplete
            id="estado"
            size="small"
            value={estado || ""}
            onChange={onSelecEstado}
            options={listEstado}
            getOptionLabel={(option) => (option?.idEstado ? option?.nombre : "")}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Estado"
                name="estado"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            //style={{ color: lightGreen[700] }}
            startIcon={<AddIcon />}
            onClick={() => getFiltro()}
          >
            Filtrar
          </Button>
          {quitarFitro && (
            <Tooltip title="Quitar filtros" arrow>
              <IconButton
                aria-label="detalle"
                size="small"
                className={classes.iconButton}
                onClick={() => {
                  getPedido()
                }}
              >
                <CloseIcon style={{ color: red[600] }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  )

  const columns = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true,
    },
    {
      title: "Orden #",
      field: "id_orden",
      width: "10%",
    },
    {
      title: "Fecha",
      field: "fecha",
      width: "10%",
    },
    {
      title: "Cliente",
      field: "nombre",
      //  width: "15%",
    },

    {
      title: "Contacto",
      field: "celular",
      width: "12%",
    },

    {
      title: "Estado",
      field: "estado_orden",
      width: "14%",
      align: "center",
      render: (rowData) =>
        rowData.estado_orden === "PENDIENTE" ? (
          <Alert
            icon={false}
            variant="filled"
            severity="error"
            style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
          >
            {rowData?.estado_orden}{" "}
          </Alert>
        ) : rowData.estado_orden === "COCINA" ? (
          <Alert
            icon={false}
            variant="filled"
            severity="warning"
            style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
          >
            {rowData?.estado_orden}
          </Alert>
        ) : rowData.estado_orden === "CAMINO" ? (
          <Alert
            icon={false}
            variant="filled"
            severity="info"
            style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
          >
            {rowData?.estado_orden}
          </Alert>
        ) : (
          <Alert
            icon={false}
            variant="filled"
            severity="success"
            style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
          >
            {rowData?.estado_orden}
          </Alert>
        ),
    },
    {
      title: "Monto",
      field: "total",
      width: "14%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={false}
          variant="outlined"
          severity="success"
          style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
        >
          {parseFloat(rowData?.total).toLocaleString("es")}
        </Alert>
      ),
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const handleDetalle = (event, props) => {
    event.stopPropagation()
    history.push("./detalle/", props.data)
  }

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circle">
                <VisibilityIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>
      </>
    )
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card style={{ backgroundColor: red[600] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <TimerIcon fontSize="large" style={{ color: red[600] }} />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      PENDIENTES
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.PENDIENTES}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card style={{ backgroundColor: orange[600] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <RestaurantMenuIcon
                        fontSize="large"
                        style={{ color: orange[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      EN COCINA
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.EN_COCINA}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card style={{ backgroundColor: blue[600] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <MotorcycleIcon
                        fontSize="large"
                        style={{ color: blue[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      EN CAMINO
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.EN_CAMINO}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card style={{ backgroundColor: green[600] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <AssignmentTurnedInIcon
                        fontSize="large"
                        style={{ color: green[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      ENTREGADOS
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.ENGREGADOS}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <MakeTables
                isLoading={isLoading}
                title={title}
                columns={columns}
                data={data.content}
                actions={actions}
                classes={classes}
                options={options}
                componentsAssets={{
                  classes,
                  //  detalle,
                  //eliminar,
                  //agregar,
                  childrenAccions,
                  // childrenToolbar,
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
