import React, { useState, useEffect } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import Box from "@material-ui/core/Box"
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import CardHeader from "@material-ui/core/CardHeader"
import Chip from "@material-ui/core/Chip"
import TypographyBold from "../../components/TypographyBold"
import AssignmentIcon from "@material-ui/icons/Assignment"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import swal from "sweetalert"
import BackdropCustom from "../../components/BackdropCustom"
import { alertWarningError } from "../../components/Notificaciones"
import Divider from "@material-ui/core/Divider"
import Avatar from "@material-ui/core/Avatar"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import AddIcon from "@material-ui/icons/AddCircle"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import EditIcon from "@material-ui/icons/Edit"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { pink } from "@material-ui/core/colors"

const inicialValue = {
  id_etapa: 0,
  nombre: "",
  orden: 0,
}

export default function DetalleEtapa() {
  const history = useHistory()
  const classes = useStyles()
  const dataProps = history.location.state

  const [isLoading, setIsLoading] = useState(false)
  const [etapa, setEtapa] = useState(inicialValue)
  const [estado, setEstado] = useState({})
  const [estados, setEstados] = useState({ content: [] })
  const [tipoUsuario, setTipoUsuario] = useState({ content: [] })
  const [open, setOpen] = useState(false)
  const [openEditar, setOpenEditar] = useState(false)
  const [openTipoUsuario, setOpenTipoUsuario] = useState(false)
  const [tipoUsuarioList, setTipoUsuarioList] = useState({ content: [] })

  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.idEtapa > 0) {
      let copyInput = {
        ...etapa,
        id_etapa: dataProps?.idEtapa,
        nombre: dataProps?.nombre,
        orden: dataProps?.orden,
      }
      setEtapa(copyInput)
      getEstados(dataProps?.idEtapa)
      getTipoUsuario(dataProps?.idEtapa)
      getTipoUsuarioList()
    }
  }, [])

  const getTipoUsuarioList = async () => {
    let url = "colaboradores/tipo-listar"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const tipoUsuario = response.data
        setTipoUsuarioList({ ...tipoUsuarioList, content: tipoUsuario?.result })
      }
    } catch (error) {
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getEstados = async (props) => {
    setIsLoading(true)
    let url = `etapas/estado-listar/${props}`
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const estadoList = response.data
        setEstados({ ...estados, content: estadoList?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getTipoUsuario = async (props) => {
    setIsLoading(true)
    let url = `etapas/etapa-tipo-usuario-listar`
    try {
      const response = await axios.post(url, {
        idEtapa: props,
      })
      let status = response.status
      if (status === 200) {
        const list = response.data
        setTipoUsuario({ ...tipoUsuario, content: list?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleCloseEditar = () => {
    setOpenEditar(false)
  }
  const handleCloseTipoUsuario = () => {
    setOpenTipoUsuario(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const handleOpenTipoUsuario = () => {
    setOpenTipoUsuario(true)
  }

  const handleOpenEditar = (event, props) => {
    event.stopPropagation()
    console.log(props)
    setEstado(props)
    setOpenEditar(true)
  }

  function AgregarEstado(props) {
    const { open, onClose, data } = props
    const [estado, setEstado] = useState({
      nombre: "",
      orden: 0,
      estadoFinal: false,
    })

    console.log(data)

    const handleGuardar = async () => {
      onClose()
      let url = "etapas/estado-crear"

      let newObject = {
        idEtapa: data?.id_etapa,
        nombre: estado.nombre,
        orden: estado.orden,
        estadoFinal: estado.estadoFinal,
      }
      console.log(newObject)

      try {
        const response = await axios.post(url, newObject)
        let status = response.status
        if (status === 200) {
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.go(0)
        }
      } catch (error) {
        if (error.response) {
          alertWarningError(error.response)
        }
      }
    }

    const handleChangeNombre = (event) => {
      let copyInput = { ...estado, nombre: event.target.value }
      setEstado(copyInput)
    }

    const handleChangeOrden = (event) => {
      let copyInput = { ...estado, orden: Number(event.target.value) }
      setEstado(copyInput)
    }

    const handleChangeEstadoFinal = (event) => {
      let copyInput = { ...estado, estadoFinal: event.target.checked }
      setEstado(copyInput)
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          NUEVO ESTADO
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="descripcion"
                name="descripcion"
                value={estado.nombre}
                onChange={(value) => handleChangeNombre(value)}
                label="Nombre del estado"
                type="text"
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

            <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
              <TextField
                size="small"
                variant="outlined"
                id="Orden"
                name="Orden"
                value={estado.orden}
                onChange={(value) => handleChangeOrden(value)}
                label="Orden"
                type="number"
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

            <Grid item xs={11} sm={11} md={5} lg={5} xl={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={estado.estadoFinal}
                    onChange={handleChangeEstadoFinal}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Estado Final"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={Object.keys(estado).length === 0 ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  function AgregarTipoUsuario(props) {
    const { open, onClose, data } = props
    const [estado, setEstado] = useState({
      nombre: "",
      orden: 0,
      estadoFinal: false,
    })
    const [tipoUsuario, setTipoUsuario] = useState({})

    console.log(data)

    const handleGuardar = async () => {
      onClose()
      let url = "etapas/etapa-tipo-usuario"

      let newObject = {
        idEtapa: data?.id_etapa,
        idTipo: tipoUsuario?.id_tp_usuario_crm,
      }
      console.log(newObject)

      try {
        const response = await axios.post(url, newObject)
        let status = response.status
        if (status === 200) {
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.go(0)
        }
      } catch (error) {
        if (error.response) {
          alertWarningError(error.response)
        }
      }
    }
    const onSelectTipoUsuario = (e, value) => {
      if (value && value?.id_tp_usuario_crm !== tipoUsuario?.id_tp_usuario_crm) {
        setTipoUsuario(value)
      }

      if (value === null) {
        setTipoUsuario({})
      }
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          ASIGNAR TIPO USUARIO
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Autocomplete
                id="tipoUsuario"
                size="small"
                value={tipoUsuario || ""}
                onChange={onSelectTipoUsuario}
                options={tipoUsuarioList?.content}
                getOptionLabel={(option) => (option.nombre ? option.nombre : "")}
                renderOption={(option) => (
                  <React.Fragment>{option?.nombre}</React.Fragment>
                )}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tipo usuario"
                    name="tipoUsuario"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={Object.keys(estado).length === 0 ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  function EditarEstado(props) {
    const { open, onClose, data } = props
    const [estado, setEstado] = useState({
      idEtapa: 0,
      idEstado: 0,
      nombre: "",
      orden: 0,
      estadoFinal: false,
    })
    useEffect(() => {
      console.log(data)
      if (data?.idEtapa > 0) {
        let copyInput = {
          ...estado,
          idEtapa: data?.idEtapa,
          idEstado: data?.idEstado,
          nombre: data?.nombre,
          orden: data?.orden,
          estadoFinal: data?.estadoFinal === 1 ? true : false,
        }
        setEstado(copyInput)
      }
    }, [])

    const handleGuardar = async () => {
      onClose()
      let url = "etapas/estado-actualizar"

      let newObject = {
        idEtapa: data?.id_etapa,
        nombre: estado.nombre,
        orden: estado.orden,
        estadoFinal: estado.estadoFinal,
      }
      console.log(newObject)

      try {
        const response = await axios.post(url, estado)
        let status = response.status
        if (status === 200) {
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.go(0)
        }
      } catch (error) {
        if (error.response) {
          alertWarningError(error.response)
        }
      }
    }

    const handleChangeNombre = (event) => {
      let copyInput = { ...estado, nombre: event.target.value }
      setEstado(copyInput)
    }

    const handleChangeOrden = (event) => {
      let copyInput = { ...estado, orden: Number(event.target.value) }
      setEstado(copyInput)
    }

    const handleChangeEstadoFinal = (event) => {
      let copyInput = { ...estado, estadoFinal: event.target.checked }
      setEstado(copyInput)
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          EDITAR ESTADO
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="descripcion"
                name="descripcion"
                value={estado.nombre}
                onChange={(value) => handleChangeNombre(value)}
                label="Nombre del estado"
                type="text"
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

            <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
              <TextField
                size="small"
                variant="outlined"
                id="Orden"
                name="Orden"
                value={estado.orden}
                onChange={(value) => handleChangeOrden(value)}
                label="Orden"
                type="number"
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

            <Grid item xs={11} sm={11} md={5} lg={5} xl={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={estado.estadoFinal}
                    onChange={handleChangeEstadoFinal}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Estado Final"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={Object.keys(estado).length === 0 ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    console.log(props)
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar  ${props?.nombre}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        inactivar(props)
      }
    })
  }

  const inactivar = async (props) => {
    setIsLoading(true)
    let url = "etapas/estado-inactivar"
    try {
      const response = await axios.post(url, {
        idEtapa: props?.idEtapa,
        idEstado: props?.idEstado,
      })
      let status = response.status
      if (status === 200) {
        getEstados(props?.idEtapa)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const handleInactivarTipoUsuario = (event, props) => {
    event.stopPropagation()
    console.log(props)
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar  ${props?.tipoUsuario}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        inactivarTipoUsuario(props)
      }
    })
  }

  const inactivarTipoUsuario = async (props) => {
    setIsLoading(true)
    let url = "etapas/etapa-tipo-usuario-inactivar"
    try {
      const response = await axios.post(url, {
        idEtapa: props?.idEtapa,
        idTipo: props?.idTipoUsuario,
      })
      let status = response.status
      if (status === 200) {
        getTipoUsuario(props?.idEtapa)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  return (
    <>
      <BackdropCustom open={isLoading} />
      <Card className={classes.root}>
        <CardHeader
          title={
            <TypographyBold variant="body1">
              {etapa?.nombre}
              <Chip
                label={"ID " + etapa?.id_etapa}
                variant="default"
                color="primary"
                size="small"
                icon={<AssignmentIcon />}
              />
            </TypographyBold>
          }
        />
        <CardContent>
          <Grid container direction="row" justify="flex-start" alignContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box mb={2}>
                <Button
                  size="small"
                  variant="outlined"
                  style={{ color: pink[600], marginRight: 10 }}
                  startIcon={<AddIcon />}
                  onClick={() => handleOpen()}
                >
                  Nuevo estado
                </Button>
              </Box>

              <TypographyBold variant="body2" display="block" color="textSecondary">
                LISTA DE ESTADOS
              </TypographyBold>

              <Box ml={1} mt={2} mb={1}>
                <Grid container direction="row" spacing={2}>
                  {estados.content.length > 0 ? (
                    estados.content.map((value) => {
                      return (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card>
                              <CardHeader
                                avatar={
                                  <Avatar style={{ backgroundColor: "#004AAD" }}>
                                    <CheckBoxIcon style={{ color: "#ffffff" }} />
                                  </Avatar>
                                }
                                title={
                                  <Typography variant="body1" display="block">
                                    {value?.nombre}
                                  </Typography>
                                }
                                subheader={
                                  <Chip
                                    label={"Orden: " + value?.orden}
                                    variant="default"
                                    color="secondary"
                                    size="small"
                                    icon={<AssignmentIcon />}
                                  />
                                }
                              />
                              <CardActions>
                                <Grid
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignContent="center"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <Chip
                                      onClick={(e) => handleOpenEditar(e, value)}
                                      //  onClick={(e) =>  history.push("./editar/", value.data)}
                                      avatar={
                                        <Avatar
                                          variant="circle"
                                          sx={{ bgcolor: "red" }}
                                        >
                                          <EditIcon
                                            fontSize="small"
                                            style={{ color: "#ffffff" }}
                                          />
                                        </Avatar>
                                      }
                                      label="Editar"
                                      variant="outlined"
                                      color="secondary"
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Chip
                                      onClick={(e) => handleInactivar(e, value)}
                                      avatar={
                                        <Avatar
                                          variant="circle"
                                          sx={{ bgcolor: "red" }}
                                        >
                                          <HighlightOffIcon
                                            fontSize="small"
                                            style={{ color: "#ffffff" }}
                                          />
                                        </Avatar>
                                      }
                                      label="Inactivar"
                                      variant="outlined"
                                      color="error"
                                    />
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          </Grid>
                        </>
                      )
                    })
                  ) : (
                    <Chip
                      label={"SIN ESTADOS ASIGNADOS"}
                      variant="default"
                      color="secondary"
                      size="small"
                      // icon={<AssignmentIcon />}
                    />
                  )}
                </Grid>
              </Box>

              <Box mb={2} mt={4}>
                <Button
                  size="small"
                  variant="outlined"
                  style={{ color: pink[600] }}
                  startIcon={<VerifiedUserIcon />}
                  onClick={() => handleOpenTipoUsuario()}
                >
                  Asignar tipo usuario
                </Button>
              </Box>
              <TypographyBold variant="body2" display="block" color="textSecondary">
                LISTA DE TIPO USUARIO
              </TypographyBold>

              <Box ml={1} mt={2} mb={1}>
                <Grid container direction="row" spacing={2}>
                  {tipoUsuario.content.length > 0 ? (
                    tipoUsuario.content.map((value) => {
                      return (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                            <Card>
                              <CardHeader
                                avatar={
                                  <Avatar style={{ backgroundColor: "#004AAD" }}>
                                    <CheckBoxIcon style={{ color: "#ffffff" }} />
                                  </Avatar>
                                }
                                title={
                                  <Typography variant="button" display="block">
                                    {value?.tipoUsuario}
                                  </Typography>
                                }
                                subheader={
                                  <Chip
                                    label={
                                      value?.esAdmin ? "Admin: SI" : "Admin: NO"
                                    }
                                    variant="default"
                                    color="secondary"
                                    size="small"
                                    icon={<VerifiedUserIcon />}
                                  />
                                }
                              />
                              <CardActions>
                                <Grid
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignContent="center"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <Chip
                                      onClick={(e) =>
                                        handleInactivarTipoUsuario(e, value)
                                      }
                                      avatar={
                                        <Avatar
                                          variant="circle"
                                          sx={{ bgcolor: "red" }}
                                        >
                                          <HighlightOffIcon
                                            fontSize="small"
                                            style={{ color: "#ffffff" }}
                                          />
                                        </Avatar>
                                      }
                                      label="Inactivar"
                                      variant="outlined"
                                      color="error"
                                    />
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          </Grid>
                        </>
                      )
                    })
                  ) : (
                    <Chip
                      label={"SIN TIPO USUARIO ASIGNADOS"}
                      variant="default"
                      color="secondary"
                      size="small"
                      // icon={<AssignmentIcon />}
                    />
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      {open && <AgregarEstado open={open} onClose={handleClose} data={etapa} />}
      {openTipoUsuario && (
        <AgregarTipoUsuario
          open={openTipoUsuario}
          onClose={handleCloseTipoUsuario}
          data={etapa}
        />
      )}
      {openEditar && (
        <EditarEstado open={openEditar} onClose={handleCloseEditar} data={estado} />
      )}
    </>
  )
}
