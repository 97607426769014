import React, { useEffect, useRef, useState } from "react"
import { useContext } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import UserContext from "../../utils/user/UserContext"
import { useStyles } from "../../assets/styles/CustomStyles"
import AccesoDenegado from "../../components/AccesoDenegado"
import {
  MapContainer,
  TileLayer,
  Polygon,
  Tooltip,
  Marker,
  useMap,
  useMapEvent,
} from "react-leaflet"
import "leaflet/dist/leaflet.css"
import { iconPerson } from "../../components/IconMap"
import { iconUserLocation } from "../../components/CurrentIconMap"
import SaveIcon from "@material-ui/icons/Save"
import { BotonVerde } from "../../assets/styles/StyledButtons"
import { CardActions, Grid, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import axios from "../../utils/axios"
import { alertWarningError } from "../../components/Notificaciones"
import dayjs from "dayjs"

export function CrearTramosPunteros() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()

  const [punteroData, setPunteroData] = useState()
  const [assignedDate, setAssignedDate] = useState()

  const [markersPointer, setMarkersPointer] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })

  document.addEventListener("keypress", (event) => {
    if (event.ctrlKey && event.code === "KeyZ") {
      const temp = [...markersPointer]
      temp.pop()

      setMarkersPointer(temp)
    }
  })

  useEffect(() => {
    getColaboradores()
  }, [])

  const getColaboradores = async (props) => {
    setIsLoading(true)
    let url = "colaboradores/colaborador-listar/"
    try {
      const response = await axios.post(url, {
        idColaborador: null,
      })
      let status = response.status
      if (status === 200) {
        const colaboradores = response.data
        setData({ ...data, content: colaboradores?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const onSelectPuntero = (e, value) => {
    const selected = data.content.find((element) => element.id === value.id)

    setPunteroData(selected)
  }

  const savePuntoDeRecorrido = async () => {
    if (!punteroData || !assignedDate || markersPointer.length < 3) {
      alertWarningError({
        data: { level: "WARNING", message: "Faltan datos por cargar!" },
      })
      return
    }

    const data = {
      puntero_id: punteroData.id,
      latLong: markersPointer,
      type: "Puntero",
      status: "Activo",
      assigned_date: assignedDate,
    }

    setIsLoading(true)
    let url = "tramos/crear-tramo/"
    try {
      const response = await axios.post(url, data)
      let status = response.status
      if (status === 201) {
        history.push("/gestionar-tramos")

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const [lastDeletedItemPos, setLastDeletedItemPos] = useState(null)

  const clickMarker = (e) => {
    const { lat, lng } = e.latlng

    const temp = [...markersPointer]

    const findItem = temp.findIndex((x) => x[0] === lat && x[1] === lng)

    temp.splice(findItem, 1)

    setMarkersPointer(temp)
    setLastDeletedItemPos(findItem)
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <CardActions style={{ marginBottom: 20 }}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignContent="center"
              spacing={2}
            >
              <Grid item sm={5}>
                <Autocomplete
                  id="tipoPuntero"
                  size="small"
                  value={punteroData || ""}
                  onChange={onSelectPuntero}
                  options={data.content}
                  getOptionLabel={(option) =>
                    option.nombre
                      ? `${option?.nombre} ${option?.apellido} - ${option.tipoUsuario}`
                      : ""
                  }
                  renderOption={(option) => (
                    <React.Fragment>{`${option?.nombre} ${option?.apellido} - ${option.tipoUsuario}`}</React.Fragment>
                  )}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Seleccione un puntero para el recorrido"
                      name="tipoPuntero"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  id="fecha"
                  name="fecha"
                  label="Fecha asignada"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  value={assignedDate}
                  onChange={(val) => setAssignedDate(val.target.value)}
                />
              </Grid>
              <Grid item>
                <BotonVerde
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<SaveIcon />}
                  loading={isLoading}
                  onClick={savePuntoDeRecorrido}
                >
                  Guardar
                </BotonVerde>{" "}
              </Grid>
            </Grid>
          </CardActions>
          <MapContainer
            center={[-25.338719, -57.487774]}
            zoom={16}
            style={{ height: "800px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://carto.com/">CARTO</a>'
            />
            <LocationMarker />
            <SetViewOnClick
              markersPointer={markersPointer}
              setMarkersPointer={setMarkersPointer}
              lastDeletedItemPos={lastDeletedItemPos}
            />
            <Polygon positions={markersPointer} pathOptions={{ color: "purple" }}>
              <Tooltip sticky>Preview recorrido</Tooltip>
            </Polygon>
            {markersPointer.map((item, index) => {
              return (
                <Marker
                  key={index}
                  position={{ lat: item[0], lng: item[1] }}
                  icon={iconPerson}
                  eventHandlers={{
                    click: clickMarker,
                  }}
                ></Marker>
              )
            })}
          </MapContainer>
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}

function LocationMarker() {
  const position = { lat: -25.3388775, lng: -57.4878309 }

  const map = useMap()

  useEffect(() => {
    map.flyTo(position, map.getZoom())
  }, [])

  return position === null ? null : (
    <Marker position={position} icon={iconUserLocation}></Marker>
  )
}

function SetViewOnClick({ markersPointer, setMarkersPointer, lastDeletedItemPos }) {
  useMapEvent("click", (e) => {
    if (!e.originalEvent.ctrlKey) {
      const temp = [...markersPointer]
      temp.push([e.latlng.lat, e.latlng.lng])

      setMarkersPointer(temp)
    } else if (e.originalEvent.ctrlKey && lastDeletedItemPos !== null) {
      // const temp = [...markersPointer].splice(lastDeletedItemPos)
      // const tempOthers = [...markersPointer].splice(0, lastDeletedItemPos)
      // temp[lastDeletedItemPos] = [e.latlng.lat, e.latlng.lng]
      // setMarkersPointer([...temp, ...tempOthers])
    }
  })

  return null
}
