import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import UserContext from "../../utils/user/UserContext"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import BackdropCustom from "../../components/BackdropCustom"

const inicialValue = {
  nombre: "",
  descripcion: "",
  costo: 0,
  idMercado: 0,
  urlSitio: "",
  facebook: "",
  instagram: "",
  otrasRedes: "",
  nroContacto: "",
  latitud: "",
  longitud: "",
  whatsapp: "",
  fechaDesde: "",
  fechaHasta: "",
  categoria: "",
}

export default function NuevaOferta() {
  const history = useHistory()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMercado, setIsLoadingMercado] = useState(false)
  const [oferta, setOferta] = useState(inicialValue)
  const [fotoPerfil, setFotoPerfil] = useState({})
  const [categoria, setCategoria] = useState({})
  const [mercado, setMercado] = useState({})
  const [mercadoList, setMercadoList] = useState({ content: [] })

  useEffect(() => {
    getData()
  }, [])

  const getData = async (props) => {
    setIsLoadingMercado(true)
    let url = "/ofertas/mercado-listar"
    try {
      const response = await axios.post(url)
      let status = response.status
      if (status === 200) {
        const mercado = response.data
        setMercadoList({ ...mercadoList, content: mercado?.result })

        setIsLoadingMercado(false)
      }
    } catch (error) {
      setIsLoadingMercado(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const uploaderProps = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader()
      console.log(file)

      const duplicado = fotoPerfil?.file?.uid === file.uid

      console.log(duplicado)
      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file)
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {
          let base64 = reader.result.split(";base64,")
          let documentoBase64 = base64[1]
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          }

          console.log(fotoPerfil)
          setFotoPerfil(imagen)
        }

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          console.log("couldn't read the file")
        }
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      console.log("onError", err)
    },
    capture: "josue",
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "configuraciones/oferta-crear"
    try {
      const response = await axios.post(url, oferta)
      let status = response.status
      if (status === 200) {
        if (Object.keys(fotoPerfil).length === 0) {
          setIsLoading(false)
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.goBack()
        } else {
          handleEnviarFotoPerfil(response.data?.result?.idOfertas)
        }
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const handleEnviarFotoPerfil = async (props) => {
    const formData = new FormData()
    formData.append("imgs", fotoPerfil?.file)
    try {
      const response = await axios.post(
        `configuraciones/foto-oferta/${props}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...oferta, nombre: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeDescripcion = (event) => {
    let copyInput = { ...oferta, descripcion: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeCosto = (event) => {
    let copyInput = { ...oferta, costo: Number(event.target.value) }
    setOferta(copyInput)
  }

  const handleChangeUrlSitio = (event) => {
    let copyInput = { ...oferta, urlSitio: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeFacebook = (event) => {
    let copyInput = { ...oferta, facebook: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeInstagram = (event) => {
    let copyInput = { ...oferta, instagram: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeOtrasRedes = (event) => {
    let copyInput = { ...oferta, otrasRedes: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeNumeroContacto = (event) => {
    let copyInput = { ...oferta, nroContacto: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeWhatsapp = (event) => {
    let copyInput = { ...oferta, whatsapp: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeLatitud = (event) => {
    let copyInput = { ...oferta, latitud: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeLongitud = (event) => {
    let copyInput = { ...oferta, longitud: event.target.value }
    setOferta(copyInput)
  }
  const handleChangeFechaDesde = (event) => {
    let copyInput = { ...oferta, fechaDesde: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeFechaHasta = (event) => {
    let copyInput = { ...oferta, fechaHasta: event.target.value }
    setOferta(copyInput)
  }
  const handleEliminarImagen = (event) => {
    event.preventDefault()
    setFotoPerfil({})
  }

  const onSelectCategoria = (e, value) => {
    if (value && value !== categoria) {
      setCategoria(value)
      let copyInput = { ...oferta, categoria: value }
      setOferta(copyInput)
    }

    if (value === null) {
      setCategoria({})
      let copyInput = { ...oferta, categoria: 0 }
      setOferta(copyInput)
    }
  }

  const onSelectMercado = (e, value) => {
    if (value && value?.idMercado !== mercado?.idMercado) {
      setMercado(value)
      let copyInput = { ...oferta, idMercado: value?.idMercado }
      setOferta(copyInput)
    }

    if (value === null) {
      setMercado({})
      let copyInput = { ...oferta, idMercado: 0 }
      setOferta(copyInput)
    }
  }
  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              {Object.keys(fotoPerfil).length === 0 ? (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Avatar alt="Foto Perfil" style={{ width: 100, height: 100 }}>
                    <PhotoCameraIcon fontSize="large" />
                  </Avatar>
                </Box>
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => handleEliminarImagen(event)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      alt="Foto Perfil"
                      src={`data:${fotoPerfil?.type};base64,${fotoPerfil?.data}`}
                      style={{ width: 140, height: 140 }}
                    />
                  </Badge>
                </Box>
              )}

              <Upload {...uploaderProps}>
                <Button
                  size="small"
                  //  startIcon={<PhotoCameraIcon />}
                  //startIcon={PublishIcon}
                  variant="contained"
                  color="primary"
                  children="Foto de Oferta"
                />
              </Upload>
            </Grid>
            <Grid item xs={12} sm={6} md={9} lg={9} xl={9}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={5} lg={5} xl={5}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={oferta.nombre}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={7} lg={7} xl={7}>
                  <TextField
                    size="small"
                    value={oferta.descripcion}
                    onChange={(value) => handleChangeDescripcion(value)}
                    variant="outlined"
                    id="descripcion"
                    name="descripcion"
                    label="Descripción "
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <Autocomplete
                    size="small"
                    value={mercado || ""}
                    onChange={onSelectMercado}
                    options={mercadoList?.content}
                    getOptionLabel={(option) =>
                      option.idMercado ? option.nombre : ""
                    }
                    loading={isLoadingMercado}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Mercado"
                        name="Mercado"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingMercado ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.whatsapp}
                    onChange={(value) => handleChangeWhatsapp(value)}
                    variant="outlined"
                    id="Whatsapp"
                    name="Whatsapp"
                    label="Whatsapp"
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.nroContacto}
                    onChange={(value) => handleChangeNumeroContacto(value)}
                    variant="outlined"
                    id="telefono"
                    name="telefono"
                    label="Teléfono"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.urlSitio}
                    onChange={(value) => handleChangeUrlSitio(value)}
                    variant="outlined"
                    id="urlSitio"
                    name="urlSitio"
                    label="URL Sitio"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.facebook}
                    onChange={(value) => handleChangeFacebook(value)}
                    variant="outlined"
                    id="facebook"
                    name="facebook"
                    label="Facebook"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.instagram}
                    onChange={(value) => handleChangeInstagram(value)}
                    variant="outlined"
                    id="instagram"
                    name="instagram"
                    label="Instagram"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.otrasRedes}
                    onChange={(value) => handleChangeOtrasRedes(value)}
                    variant="outlined"
                    id="otrasRedes"
                    name="otrasRedes"
                    label="Otras Redes"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.latitud}
                    onChange={(value) => handleChangeLatitud(value)}
                    variant="outlined"
                    id="Latitud"
                    name="Latitud"
                    label="Latitud"
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.longitud}
                    onChange={(value) => handleChangeLongitud(value)}
                    variant="outlined"
                    id="Longitud"
                    name="Longitud"
                    label="Longitud"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <TextField
                    size="small"
                    value={oferta.fechaDesde}
                    onChange={(value) => handleChangeFechaDesde(value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="desde"
                    name="desde"
                    label="Fecha desde"
                    type="date"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <TextField
                    size="small"
                    value={oferta.fechaHasta}
                    onChange={(value) => handleChangeFechaHasta(value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="hasta"
                    name="hasta"
                    label="Fecha hasta"
                    type="date"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <Autocomplete
                    size="small"
                    value={categoria || ""}
                    onChange={onSelectCategoria}
                    options={[
                      "Carnes",
                      "Panificados",
                      "Aceites",
                      "Enlatados",
                      "Salsas",
                      "Frutas",
                      "Verduras hortalizas",
                      "Embutidos",
                    ]}
                    getOptionLabel={(option) => (option ? option : "")}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Categoría"
                        name="Categoría"
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <TextField
                    size="small"
                    value={oferta.costo}
                    onChange={(value) => handleChangeCosto(value)}
                    variant="outlined"
                    id="precio"
                    name="precio"
                    label="Precio"
                    type="number"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
