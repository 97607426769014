import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Chip } from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import LinearProgress from "@material-ui/core/LinearProgress"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import { Grid, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import {
  pink,
  lightGreen,
  orange,
  deepPurple,
  indigo,
  cyan,
} from "@material-ui/core/colors"
import swal from "sweetalert"
import TypographyBold from "../../components/TypographyBold"
import TimerIcon from "@material-ui/icons/Timer"
import TodayIcon from "@material-ui/icons/Today"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import EventIcon from "@material-ui/icons/Event"
import EditIcon from "@material-ui/icons/Edit"
import CloseIcon from "@material-ui/icons/Close"

import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Avatar from "@material-ui/core/Avatar"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import HistoryIcon from "@material-ui/icons/History"
import CachedIcon from "@material-ui/icons/Cached"
import Alert from "@material-ui/lab/Alert"
import Check from "@material-ui/icons/Check"
import VisibilityIcon from "@material-ui/icons/Visibility"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

const initFiltro = {
  fechaDesde: null,
  fechaHasta: null,
  idUsuario: null,
  estado: null,
}
export default function ListaVisitas() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    content: [],
    usuarios: [],
  })
  const [isLoadingEstado, setIsLoadingEstado] = useState(false)
  const [estadoList, setEstadoList] = useState({ content: [] })
  const [visita, setVisita] = useState({})
  const [open, setOpen] = useState(false)
  const [openReagendar, setOpenReagendar] = useState(false)
  const [openLoad, setOpenLoad] = useState(false)
  const [quitarFitro, setQuitarFiltro] = useState(false)

  const [estado, setEstado] = useState({})
  const [usuario, setUsuario] = useState({})
  const [dashboard, setDashboard] = useState({})
  const [filtro, setFiltro] = useState(initFiltro)

  useEffect(() => {
    getVisitas("listar")
    getEstados()
    getDashboard()
  }, [])

  const getVisitas = async (props) => {
    setIsLoading(true)
    setQuitarFiltro(false)
    setFiltro(initFiltro)
    setUsuario({})
    setEstado({})
    let url = "visitas/visitas-listar"
    try {
      const response = await axios.post(url, initFiltro)
      let status = response.status
      if (status === 200) {
        const visitaResponse = response.data
        console.log(visitaResponse?.result)
        setData({
          ...data,
          content: visitaResponse?.visitas,
          // dashboard: visitaResponse?.dashboard[0],
          usuarios: visitaResponse?.usuarios,
        })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const getEstados = async (props) => {
    setIsLoadingEstado(true)
    let url = "visitas/visita-estado-listar"
    try {
      const response = await axios.post(url)
      let status = response.status
      if (status === 200) {
        const estado = response.data
        setEstadoList({ ...estadoList, content: estado?.result })

        setIsLoadingEstado(false)
      }
    } catch (error) {
      setIsLoadingEstado(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getDashboard = async (props) => {
    setIsLoading(true)
    let url = "visitas/visitas-dashboard"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const dash = response.data
        setDashboard(dash?.result[0])

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nueva-visita/")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      //hidden: true,
    },

    {
      title: "Cliente",
      field: "cliente",
      //  width: "15%",
    },
    {
      title: "Nro contacto",
      field: "nro_contacto",
      width: "10%",
    },
    {
      title: "Nombre",
      field: "nomb_usuario",
      width: "15%",
    },
    {
      title: "Apellido",
      field: "ape_usuario",
      width: "15%",
    },
    {
      title: "Fecha",
      field: "fecha_visita",
      width: "10%",
      render: (rowData) => (
        <Chip
          //  icon={<TodayIcon />}
          color="secondary"
          label={rowData?.fecha_visita}
          size="small"
        />
      ),
    },

    {
      title: "Horas",
      field: "hora_visita",
      width: "10%",
      render: (rowData) => (
        <Chip
          // icon={<TimerIcon />}
          color="primary"
          label={rowData?.hora_visita}
          size="small"
        />
      ),
    },
    {
      title: "Estado",
      field: "estado_visita",
      width: "10%",
      render: (rowData) => (
        <Chip color="primary" label={rowData?.estado_visita} size="small" />
      ),
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const handleCambiarEstado = (props) => {
    setOpen(true)
    setVisita(props)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenLoad(false)
  }

  const handleReagendar = (props) => {
    setOpenReagendar(true)
    setVisita(props)
  }

  const handleCloseReagendar = () => {
    setOpenReagendar(false)
    setOpenLoad(false)
  }

  function CambiarEstado(props) {
    const { open, onClose, data } = props
    const [estado, setEstado] = useState({})

    console.log(data)

    const onSelectEstado = (e, value) => {
      if (value && value?.idEstado !== estado?.idEstado) {
        setEstado(value)
      }

      if (value === null) {
        setEstado({})
      }
    }
    function Loaders() {
      return <LinearProgress />
    }

    const handleGuardar = async () => {
      onClose()
      let url = "visitas/visita-estado-crear"

      let newObject = {
        idVisita: data?.id,
        idEtapa: estado?.idEtapa,
        idEstado: estado?.idEstado,
      }
      console.log(newObject)

      try {
        const response = await axios.post(url, newObject)
        let status = response.status
        if (status === 200) {
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.go(0)
        }
      } catch (error) {
        onClose()

        if (error.response) {
          alertWarningError(error.response)
        }
      }
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          CAMBIAR ESTADO DE LA VISITA
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                ESTADO ACTUAL
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Chip
                  label={data?.estado}
                  variant="default"
                  color="primary"
                  icon={<AssignmentIndIcon />}
                />
              </Box>
            </Grid>

            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                SELECCIONAR ESTADO
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Autocomplete
                  id="estado"
                  onChange={onSelectEstado}
                  size="small"
                  value={estado || ""}
                  loading={isLoadingEstado}
                  options={estadoList.content}
                  getOptionLabel={(option) =>
                    option.idEstado ? option?.estado : ""
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="estado"
                      label="Estados"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingEstado ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={Object.keys(estado).length === 0 ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </DialogActions>
        {openLoad && <Loaders />}
      </Dialog>
    )
  }

  function Reagendar(props) {
    const { open, onClose, data } = props
    const [nuevaAgenda, setNuevaAgenda] = useState({
      idVisita: data?.id,
      fecha: "",
      hora: "",
      motivo: "",
    })

    console.log(data)

    const handleChangeMotivo = (event) => {
      let copyInput = {
        ...nuevaAgenda,
        motivo: event.target.value,
      }
      setNuevaAgenda(copyInput)
    }

    const handleChangeFecha = (value) => {
      console.log(value.target.value)

      let copyInput = {
        ...nuevaAgenda,
        fecha: value.target.value,
      }
      setNuevaAgenda(copyInput)
    }

    const handleChangeHora = (value) => {
      console.log(value.target.value)

      let copyInput = {
        ...nuevaAgenda,
        hora: value.target.value,
      }
      setNuevaAgenda(copyInput)
    }

    function Loaders() {
      return <LinearProgress />
    }

    const handleGuardar = async () => {
      onClose()
      let url = "visitas/visita-reagendar"

      console.log(nuevaAgenda)

      try {
        const response = await axios.post(url, nuevaAgenda)
        let status = response.status
        if (status === 200) {
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.go(0)
        }
      } catch (error) {
        onClose()

        if (error.response) {
          alertWarningError(error.response)
        }
      }
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          REAGENDAR FECHA
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                FECHA ACTUAL
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Chip
                  label={data?.fecha_visita}
                  variant="default"
                  color="primary"
                  icon={<TodayIcon />}
                />
              </Box>
            </Grid>

            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Box ml={1} mt={1} mb={1}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      variant="outlined"
                      id="fecha"
                      name="fecha"
                      label="Proxima fecha"
                      type="date"
                      size="small"
                      onChange={(e) => handleChangeFecha(e)}
                      defaultValue={nuevaAgenda.fechaProx}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      variant="outlined"
                      id="hora"
                      name="hora"
                      label="Proxima hora"
                      type="time"
                      size="small"
                      onChange={(e) => handleChangeHora(e)}
                      defaultValue={nuevaAgenda.horaProx}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="comentario"
                      name="comentario"
                      label="Motivo de reagendamiento"
                      type="text"
                      className={classes.DialogoTexto}
                      value={nuevaAgenda.motivoReagendado}
                      onChange={(value) => handleChangeMotivo(value)}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={
                  nuevaAgenda.fechaProx === "" &&
                  nuevaAgenda.horaProx === "" &&
                  nuevaAgenda.motivoReagendado === ""
                    ? true
                    : false
                }
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </DialogActions>
        {openLoad && <Loaders />}
      </Dialog>
    )
  }
  const handleDetalle = (event, props) => {
    event.stopPropagation()
    history.push("./detalle/", props.data)
  }

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    console.log(props)
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar  ${props.data?.nomb_fantasia}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        inactivar(props.data)
      }
    })
  }

  const inactivar = async (props) => {
    setIsLoading(true)
    let url = "visitas/visitas-estado"
    try {
      const response = await axios.post(url, {
        idVisita: props.id,
        estado: "INACTIVO",
      })
      let status = response.status
      if (status === 200) {
        getVisitas("listar")
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circle">
                <VisibilityIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>
        {/** 
        <Box pl={1} pr={1}>
          <Chip
            onClick={() => handleCambiarEstado(props.data)}
            avatar={
              <Avatar variant="circle"
                sx={{ bgcolor: "red" }} >
                <EditIcon fontSize='small' style={{ backgroundColor: lightGreen[600], color: "#fff" }} />
              </Avatar>
            }
            style={{ color: lightGreen[600] }}
            label="Estado"
            variant="outlined"
            color="default"
          />
        </Box>
*/}

        <Box pl={1} pr={1}>
          <Chip
            onClick={() => handleReagendar(props.data)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <EventIcon fontSize="small" />
              </Avatar>
            }
            label="Reagendar"
            variant="outlined"
            color="secondary"
          />
        </Box>
        <Box pl={1} pr={1}>
          {props?.data?.estado === "ACTIVO" ? (
            <Chip
              onClick={(e) => handleInactivar(e, props)}
              avatar={
                <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                  <HighlightOffIcon fontSize="small" style={{ color: "#ffffff" }} />
                </Avatar>
              }
              label="Inactivar"
              variant="outlined"
              color="error"
            />
          ) : (
            <Chip
              onClick={(e) => handleInactivar(e, props)}
              avatar={
                <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                  <Check fontSize="small" style={{ color: "#ffffff" }} />
                </Avatar>
              }
              label="Activar"
              variant="outlined"
              color="secondary"
            />
          )}
        </Box>
      </>
    )
  }

  const getFiltro = async (props) => {
    setData({ ...data, content: [] })
    setIsLoading(true)
    setQuitarFiltro(true)
    let url = "visitas/visitas-listar"
    try {
      const response = await axios.post(url, filtro)
      let status = response.status
      if (status === 200) {
        const carteras = response.data
        console.log(carteras)
        setData({
          ...data,
          content: carteras?.visitas,
        })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const childrenToolbar = (props) => {
    const onSelectUsuario = (e, value) => {
      console.log(value)
      if (value && value !== usuario) {
        setUsuario(value)
        let copyInput = { ...filtro, idUsuario: value?.id_usuario }
        setFiltro(copyInput)
      }

      if (value === null) {
        setUsuario({})
        let copyInput = { ...filtro, idUsuario: null }
        setFiltro(copyInput)
      }
    }

    const onSelectEstado = (e, value) => {
      if (value && value !== estado) {
        setEstado(value)
        let copyInput = { ...filtro, estado: value }
        setFiltro(copyInput)
      }

      if (value === null) {
        setEstado({})
        let copyInput = { ...filtro, estado: null }
        setFiltro(copyInput)
      }
    }

    const handleFechaDesde = (event) => {
      if (event.target.value === "") {
        let copyInput = { ...filtro, fechaDesde: null }
        setFiltro(copyInput)
      } else {
        let copyInput = { ...filtro, fechaDesde: event.target.value }
        setFiltro(copyInput)
      }
    }

    const handleFechaHasta = (event) => {
      if (event.target.value === "") {
        let copyInput = { ...filtro, fechaHasta: null }
        setFiltro(copyInput)
      } else {
        let copyInput = { ...filtro, fechaHasta: event.target.value }
        setFiltro(copyInput)
      }
    }
    return (
      <>
        <Grid container spacing={2} style={{ padding: "5px 20px" }}>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <TextField
              variant="outlined"
              id="fecha"
              name="fecha"
              label="Proxima desde"
              type="date"
              size="small"
              onChange={(e) => handleFechaDesde(e)}
              defaultValue={filtro.fechaDesde}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <TextField
              variant="outlined"
              id="fecha"
              name="fecha"
              label="Proxima hasta"
              type="date"
              size="small"
              onChange={(e) => handleFechaHasta(e)}
              defaultValue={filtro.fechaHasta}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Autocomplete
              id="usuario"
              size="small"
              value={usuario || ""}
              onChange={onSelectUsuario}
              options={data.usuarios}
              getOptionLabel={(option) =>
                option?.id_usuario ? option?.nombre + " " + option?.apellido : ""
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Usuario"
                  name="usuario"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Autocomplete
              id="estado"
              size="small"
              value={estado || ""}
              onChange={onSelectEstado}
              options={["ACTIVO", "INACTIVO", "FINALIZADO", "REAGENDADO"]}
              getOptionLabel={(option) => (option ? option : "")}
              renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Estado"
                  name="estado"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              //style={{ color: lightGreen[700] }}
              startIcon={<AddIcon />}
              onClick={() => getFiltro()}
            >
              Filtrar
            </Button>
            {quitarFitro && (
              <Tooltip title="Quitar filtros" arrow>
                <IconButton
                  aria-label="detalle"
                  size="small"
                  className={classes.iconButton}
                  onClick={() => {
                    getVisitas()
                  }}
                >
                  <CloseIcon style={{ color: pink[600] }} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Card style={{ backgroundColor: cyan[600] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <TodayIcon fontSize="large" style={{ color: cyan[600] }} />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      VISTA DEL DIA
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.visitas_dia}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Card style={{ backgroundColor: indigo[600] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <CachedIcon fontSize="large" style={{ color: indigo[600] }} />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      EN PROCESO
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.en_proceso}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Card style={{ backgroundColor: deepPurple[600] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <HistoryIcon
                        fontSize="large"
                        style={{ color: deepPurple[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      REAGENDADA
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.reagendado}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Card style={{ backgroundColor: orange[600] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <AssignmentTurnedInIcon
                        fontSize="large"
                        style={{ color: orange[600] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      TOTAL VISITA
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.visitas_ttl}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Card style={{ backgroundColor: lightGreen[800] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <CheckCircleIcon
                        fontSize="large"
                        style={{ color: lightGreen[800] }}
                      />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      FINALIZADA
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h5"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.finalizado}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <Card style={{ backgroundColor: pink[400] }}>
                <CardHeader
                  avatar={
                    <Avatar variant="rounded" style={{ backgroundColor: "#ffffff" }}>
                      <CancelIcon fontSize="large" style={{ color: pink[400] }} />
                    </Avatar>
                  }
                  title={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      CANCELADAS
                    </Typography>
                  }
                  subheader={
                    <TypographyBold
                      variant="h6"
                      display="block"
                      style={{ color: "#ffffff" }}
                    >
                      {dashboard?.cancelado}
                    </TypographyBold>
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <MakeTables
                isLoading={isLoading}
                title={title}
                columns={columns}
                data={data.content}
                actions={actions}
                classes={classes}
                options={options}
                componentsAssets={{
                  classes,
                  //  detalle,
                  //eliminar,
                  //agregar,
                  childrenAccions,
                  childrenToolbar,
                }}
              />
            </Grid>
          </Grid>

          {open && <CambiarEstado open={open} onClose={handleClose} data={visita} />}

          {openReagendar && (
            <Reagendar
              open={openReagendar}
              onClose={handleCloseReagendar}
              data={visita}
            />
          )}
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
