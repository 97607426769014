import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"

import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import Chip from "@material-ui/core/Chip"
import TodayIcon from "@material-ui/icons/Today"
import TimerIcon from "@material-ui/icons/Timer"

export default function ListaHistoricoCliente() {
  const history = useHistory()
  const dataProps = history.location.state

  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })

  useEffect(() => {
    if (dataProps?.id_cliente > 0) {
      console.log(dataProps)
      getHistoricos(dataProps?.id_cliente)
    }
  }, [])

  const getHistoricos = async (props) => {
    setIsLoading(true)
    let url = "visitas/historico/" + props
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const historicos = response.data
        setData({ ...data, content: historicos?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nuevo/")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "Cliente",
      field: "comercio",
      width: "20%",
    },

    {
      title: "Comentario",
      field: "comentario_visita",
      //  width: "15%",
    },
    {
      title: "Tipo visita",
      field: "medio_visita_solicitada",
      width: "12%",
    },
    {
      title: "Fecha",
      field: "fecha_visita",
      width: "15%",
      render: (rowData) => (
        <Chip
          icon={<TodayIcon />}
          color="secondary"
          label={rowData?.fecha_visita}
          size="small"
        />
      ),
    },

    {
      title: "Horas",
      field: "hora_visita",
      width: "10%",
      render: (rowData) => (
        <Chip
          icon={<TimerIcon />}
          color="primary"
          label={rowData?.hora_visita}
          size="small"
        />
      ),
    },

    {
      title: "Estado",
      field: "estado",
      width: "10%",
      render: (rowData) => (
        <Chip color="primary" label={rowData?.estado} size="small" />
      ),
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    showTitle: false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
            }}
          />
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
