import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import swal from "sweetalert"
import BackdropCustom from "../../components/BackdropCustom"

const inicialValue = {
  idTipoPedido: 0,
  nombre: "",
  descripcion: "",
}

export default function EditarTipoPedido() {
  const history = useHistory()
  const classes = useStyles()
  const dataProps = history.location.state
  const [isLoading, setIsLoading] = useState(false)
  const [tipoPedido, setTipoPedido] = useState(inicialValue)

  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.id_tp_pedido > 0) {
      let copyInput = {
        ...tipoPedido,
        idTipoPedido: dataProps?.id_tp_pedido,
        nombre: dataProps?.nombre,
        descripcion: dataProps?.descripcion,
      }
      setTipoPedido(copyInput)
    }
  }, [])

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "configuraciones/tipo-pedido-actualizar"

    try {
      const response = await axios.post(url, tipoPedido)
      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...tipoPedido, nombre: event.target.value }
    setTipoPedido(copyInput)
  }

  const handleChangeDescripcion = (event) => {
    let copyInput = { ...tipoPedido, descripcion: event.target.value }
    setTipoPedido(copyInput)
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="nombre"
                name="Nombre"
                label="Nombre"
                value={tipoPedido.nombre}
                onChange={(value) => handleChangeNombre(value)}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="descripcion"
                name="Descripción"
                label="Descripción"
                value={tipoPedido.descripcion}
                onChange={(value) => handleChangeDescripcion(value)}
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Actualizar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
