import React, { useState, useEffect } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import axios from "../../utils/axios"
import { TextField, Grid, Card, CardContent, CardActions } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import { alertWarningError } from "../../components/Notificaciones"
import SaveIcon from "@material-ui/icons/Save"
import swal from "sweetalert"
import BackdropCustom from "../../components/BackdropCustom"
import Autocomplete from "@material-ui/lab/Autocomplete"
import AddIcon from "@material-ui/icons/AddCircle"

const tipoDeComponente = [
  { id: 1, nombre: "TextField" },
  { id: 2, nombre: "Select" },
  { id: 3, nombre: "TextArea" },
  { id: 4, nombre: "Switch" },
]

export default function NuevoGrupoDeInformacion() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const [tipoList, setTipoList] = useState([])

  const [tipoDeInformacion, setTipoDeInformacion] = useState()

  const [mainInfo, setMainInfo] = useState([
    {
      label: null,
      value: null,
      type: null,
      place: "test",
    },
  ])

  const handleSetMainInfo = (key, pos, val) => {
    const temp = [...mainInfo]

    temp[pos][key] = val

    setMainInfo(temp)
  }

  const handleSetTipoDeInformacion = (value) => {
    const selected = tipoList.find((element) => element.tipo === value)

    setTipoDeInformacion(selected)
  }

  const getTipo = async () => {
    const url = "intereses/obtener-grupos-de-informacion"
    try {
      const responseTipo = await axios.get(url)
      const responseStatus = responseTipo.status
      if (responseStatus === 200) {
        setTipoList(responseTipo.data)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const crearGrupoDeInfoMeta = async (data) => {
    setIsLoading(true)
    const url = `intereses/crear-grupo-de-informacion-meta`

    try {
      const response = await axios.post(url, data)
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }

      throw error
    }
  }

  useEffect(() => {
    getTipo()
  }, [])

  const handleAddModal = async () => {
    try {
      const selectId = tipoList.find(
        (element) => element.tipo === tipoDeInformacion.tipo,
      )

      if (selectId) {
        for (const itemData of mainInfo) {
          const dataToSend = {}

          dataToSend.grupoDeInformacionId = Number(selectId.id)
          dataToSend.label = itemData.label
          if (itemData.value) {
            dataToSend.value = JSON.stringify(itemData.value.split(","))
          } else {
            dataToSend.value = null
          }
          dataToSend.type = itemData.type
          dataToSend.place = itemData.place // Placeholder

          await crearGrupoDeInfoMeta(dataToSend)
        }

        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addMainInfo = () => {
    const temp = [
      ...mainInfo,
      {
        label: null,
        value: null,
        type: null,
        place: "test",
      },
    ]
    setMainInfo(temp)
  }

  const renderSelectedComponent = (index) => {
    switch (mainInfo[index]["type"]) {
      case "TextField":
        return (
          <TextField
            size="small"
            variant="outlined"
            id="labelToSelect"
            name="labelToSelect"
            label="Nombre del textfield"
            type="text"
            fullWidth
            style={{ marginBottom: "10px" }}
            value={mainInfo[index]["label"]}
            onChange={(data) => handleSetMainInfo("label", index, data.target.value)}
          />
        )
      case "Select":
        return (
          <>
            <TextField
              size="small"
              variant="outlined"
              id="dataToSelect"
              name="dataToSelect"
              label="Nombre del select"
              type="text"
              fullWidth
              value={mainInfo[index]["label"]}
              onChange={(data) =>
                handleSetMainInfo("label", index, data.target.value)
              }
              style={{ marginBottom: "10px" }}
            />
            <TextField
              size="small"
              variant="outlined"
              id="labelToSelect"
              name="labelToSelect"
              label="Datos a mostrar (los valores deben ir entre coma)"
              type="text"
              fullWidth
              style={{ marginBottom: "10px" }}
              value={mainInfo[index]["value"]}
              onChange={(data) =>
                handleSetMainInfo("value", index, data.target.value)
              }
            />
          </>
        )
      case "TextArea":
        return (
          <TextField
            size="small"
            variant="outlined"
            id="labelToSelect"
            name="labelToSelect"
            label="Nombre del textarea"
            type="text"
            fullWidth
            style={{ marginBottom: "10px" }}
            value={mainInfo[index]["label"]}
            onChange={(data) => handleSetMainInfo("label", index, data.target.value)}
          />
        )
      case "Switch":
        return (
          <TextField
            size="small"
            variant="outlined"
            id="labelToSelect"
            name="labelToSelect"
            label="Nombre del switch"
            type="text"
            fullWidth
            style={{ marginBottom: "10px" }}
            value={mainInfo[index]["label"]}
            onChange={(data) => handleSetMainInfo("label", index, data.target.value)}
          />
        )
      default:
        return null
    }
  }

  // Seleccion primaria
  return (
    <>
      <BackdropCustom open={isLoading} />
      <Card className={classes.root}>
        <CardContent>
          <Autocomplete
            id="tipo"
            size="small"
            value={tipoDeInformacion || ""}
            onChange={(event) => handleSetTipoDeInformacion(event.target.innerText)}
            options={tipoList}
            getOptionLabel={(option) => (option.tipo ? option.tipo : "")}
            renderOption={(option) => (
              <React.Fragment>{option?.tipo}</React.Fragment>
            )}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Grupo de informacion"
                name="grupo_info"
              />
            )}
          />
        </CardContent>
        {mainInfo.map((_, index) => (
          <CardContent key={index}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignContent="center"
              spacing={2}
            >
              <Grid item sm={12} md={12} lg={6} xl={6}>
                <Autocomplete
                  id="tipoComponente"
                  size="small"
                  onChange={(event) =>
                    handleSetMainInfo("type", index, event.target.innerText)
                  }
                  options={tipoDeComponente}
                  getOptionLabel={(option) => (option.nombre ? option.nombre : "")}
                  renderOption={(option) => (
                    <React.Fragment>{option?.nombre}</React.Fragment>
                  )}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Seleccionar el tipo de Componente necesario"
                      name="tipoDeComponente"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: params.InputProps.endAdornment,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item sm={12} md={6} lg={6} xl={6}>
                {renderSelectedComponent(index)}
              </Grid>
            </Grid>
          </CardContent>
        ))}

        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                variant="contained"
                size="small"
                color="default"
                style={{ color: "#F09F54" }}
                startIcon={<AddIcon />}
                onClick={addMainInfo}
              >
                Agregar Grupo
              </BotonGris>
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleAddModal}
              >
                Guardar
              </BotonVerde>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
