import NotFound from "./components/NotFound"
import Login from "./page/Login"
import Tablero from "./page/Tablero"
import Colaboradores from "./page/colaboradores/Colaboradores"
import NuevoColaborador from "./page/colaboradores/NuevoColaborador"
import ListaColaboradores from "./page/colaboradores/ListaColaboradores"
import ListaTipoColaborador from "./page/colaboradores/ListaTipoColaborador"
import NuevoTipoColaborador from "./page/colaboradores/NuevoTipoColaborador"
import NuevoCliente from "./page/comercio/NuevoComercio"
import Cartera from "./page/cartera/Cartera"
import NuevaCartera from "./page/cartera/NuevaCartera"
import ListaCarteras from "./page/cartera/ListaCarteras"
import ListarComercioSinCarteras from "./page/cartera/ListarComercioSinCarteras"
import DetalleCartera from "./page/cartera/DetalleCartera"
import Visitas from "./page/visitas/Visitas"
import NuevaVisita from "./page/visitas/NuevaVisita"
import ListaVisitas from "./page/visitas/ListaVisitas"
import ListaClientesVisitas from "./page/visitas/ListaClientesVisitas"
import ListaHistoricoCliente from "./page/visitas/ListaHistoricoCliente"
import EtapasEstados from "./page/estapas-estados/EtapasEstados"
import LsitarEtapas from "./page/estapas-estados/LsitarEtapas"
import NuevaEtapa from "./page/estapas-estados/NuevaEtapa"
import DetalleEtapa from "./page/estapas-estados/DetalleEtapa"
import ListaOfertas from "./page/ofertas/ListaOfertas"
import NuevaOferta from "./page/ofertas/NuevaOferta"
import Oferta from "./page/ofertas/Oferta"
import ListaPedidos from "./page/pedidos/ListaPedidos"
import DetallePedido from "./page/pedidos/DetallePedido"
import DetalleComercio from "./page/comercio/DetalleComercio"
import DetalleOferta from "./page/ofertas/DetalleOferta"
import Comercio from "./page/comercio/Comercio"
import Pedidos from "./page/pedidos/Pedidos"
import Categoria from "./page/categoria/Categoria"
import ListarCategorias from "./page/categoria/ListarCategorias"
import NuevaCategoria from "./page/categoria/NuevaCategoria"
import FormaDePago from "./page/forma-pago/FormaDePago"
import NuevaFormaDePago from "./page/forma-pago/NuevaFormaDePago"
import ListarFormaDePago from "./page/forma-pago/ListarFormaDePago"
import TipoPedido from "./page/tipo-pedido/TipoPedido"
import NuevoTipoPedido from "./page/tipo-pedido/NuevoTipoPedido"
import ListarTipoPedido from "./page/tipo-pedido/ListarTipoPedido"
import EditarCategoria from "./page/categoria/EditarCategoria"
import EditarTipoPedido from "./page/tipo-pedido/EditarTipoPedido"
import EditarFormaDePago from "./page/forma-pago/EditarFormaDePago"
import EditarEtapa from "./page/estapas-estados/EditarEtapa"
import EditarOferta from "./page/ofertas/EditarOferta"
import ListaDestacado from "./page/destacado/ListaDestacado"
import EditarComercio from "./page/comercio/EditarComercio"
import NuevoUsuarioComercio from "./page/comercio/NuevoUsuario"
import EditarTipoColaborador from "./page/colaboradores/EditarTipoColaborador"
import EditarColaborador from "./page/colaboradores/EditarColaborador"
import EditarUsuarioComercio from "./page/comercio/EditarUsuario"
import EditarUsuario from "./page/usuarios/EditarUsuario"
import NuevoUsuario from "./page/usuarios/NuevoUsuario"
import ListaBanner from "./page/banners/ListaBanner"
import Banners from "./page/banners/Banners"
import NuevoBanner from "./page/banners/NuevoBanner"
import EditarBanner from "./page/banners/EditarBanner"
import NuevoMercado from "./page/mercado/NuevoMercado"
import ListaMercado from "./page/mercado/ListaMercado"
import EditarMercado from "./page/mercado/EditarMercado"
import MotivoCancelacion from "./page/motivo-cancelacion/MotivoCancelacion"
import ListaMotivoUsuario from "./page/motivo-cancelacion/ListaMotivoUsuario"
import NuevoMotivoUsuario from "./page/motivo-cancelacion/NuevoMotivoUsuario"
import EditarMotivoUsuario from "./page/motivo-cancelacion/EditarMotivoUsuario"
import ListaMotivoComercio from "./page/motivo-cancelacion/ListaMotivoComercio"
import NuevoMotivoComercio from "./page/motivo-cancelacion/NuevoMotivoComercio"
import EditarMotivoComercio from "./page/motivo-cancelacion/EditarMotivoComercio"
import DetalleVisita from "./page/visitas/DetalleVisita"
import ListaComercioActivado from "./page/comercio/ListaComercioActivado"
import ListaComercioActivacion from "./page/comercio/ListaComercioActivacion"
import ListaComercioActivar from "./page/comercio/ListaComercioActivar"
import ListaUsuarios from "./page/usuarios/ListaUsuarios"
import Recorrido from "./page/punto-de-interes/Recorrido"
import NuevoRecorrido from "./page/punto-de-interes/NuevoRecorrido"
import ListaPuntoDeInteres from "./page/punto-de-interes/ListaVisitas"
import DetalleDeRecorrido from "./page/visitas/DetalleVisita"
import NuevaFamiilia from "./page/punto-de-interes/NuevaFamilia"
import NuevoTipo from "./page/punto-de-interes/NuevoTipo"
import NuevoSubTipo from "./page/punto-de-interes/NuevoSubtipo"
import NuevoGrupoDeInformacion from "./page/punto-de-interes/CrearGrupoDeInformacion"
import NuevoTipoGrupoDeInformacion from "./page/punto-de-interes/CrearTipoGrupoDeInformacion "
import ListarTipo from "./page/punto-de-interes/ListaTipos"
import ListarFamilia from "./page/punto-de-interes/ListaFamilia"
import ListarSubTipo from "./page/punto-de-interes/ListaSubtipo"
import ListarGrupoInformacion from "./page/punto-de-interes/ListaGrupoInformacion"
import { CrearTramosPunteros } from "./page/gestionar-tramos/CrearTramosPunteros"
import { CrearTramosVendedores } from "./page/gestionar-tramos/CrearTramosVendedores"
import HistoricoTramos from "./page/gestionar-tramos/HistoricoTramos"
import ListarPuntoDeInteres from "./page/punto-de-interes/ListaPuntoDeInteres"
import ListaComercioMantenimiento from "./page/comercio/ListaComercioMantenimiento"
import GetAllPuntoDeInteresMaps from "./page/punto-de-interes/listPuntoDeInteresMaps"
import EditarPuntoDeInteres from "./page/punto-de-interes/EditarPuntoDeInteres"

export const routes = [
  {
    path: "/",
    label: "Inicio",
    component: Tablero,
    routes: [
      {
        path: "/acceder",
        label: "INICIAR SESION",
        component: Login,
      },

      //comercios
      {
        path: "/comercios/",
        label: "Comercios",
        component: Comercio,
        routes: [
          {
            path: "/lista-activado/",
            label: "Lista de comercio activos",
            component: ListaComercioActivado,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle",
                component: DetalleComercio,
                routes: [
                  {
                    path: "/nuevo-usuario/",
                    label: "Nuevo Usuario",
                    component: NuevoUsuarioComercio,
                  },
                  {
                    path: "/editar-usuario/",
                    label: "Editar Usuario",
                    component: EditarUsuarioComercio,
                  },
                ],
              },
              {
                path: "/editar/",
                label: "Editar",
                component: EditarComercio,
              },
            ],
          },
          {
            path: "/lista-activacion/",
            label: "Lista de comercio para activación",
            component: ListaComercioActivacion,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle",
                component: DetalleComercio,
                routes: [
                  {
                    path: "/nuevo-usuario/",
                    label: "Nuevo Usuario",
                    component: NuevoUsuarioComercio,
                  },
                  {
                    path: "/editar-usuario/",
                    label: "Editar Usuario",
                    component: EditarUsuarioComercio,
                  },
                ],
              },
              {
                path: "/editar/",
                label: "Editar",
                component: EditarComercio,
              },
            ],
          },

          {
            path: "/lista-mantenimiento/",
            label: "Lista de comercio para activación",
            component: ListaComercioMantenimiento,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle",
                component: DetalleComercio,
                routes: [
                  {
                    path: "/nuevo-usuario/",
                    label: "Nuevo Usuario",
                    component: NuevoUsuarioComercio,
                  },
                  {
                    path: "/editar-usuario/",
                    label: "Editar Usuario",
                    component: EditarUsuarioComercio,
                  },
                ],
              },
              {
                path: "/editar/",
                label: "Editar",
                component: EditarComercio,
              },
            ],
          },

          {
            path: "/lista-activar/",
            label: "Lista de comercio para activar",
            component: ListaComercioActivar,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle",
                component: DetalleComercio,
                routes: [
                  {
                    path: "/nuevo-usuario/",
                    label: "Nuevo Usuario",
                    component: NuevoUsuarioComercio,
                  },
                  {
                    path: "/editar-usuario/",
                    label: "Editar Usuario",
                    component: EditarUsuarioComercio,
                  },
                ],
              },
              {
                path: "/editar/",
                label: "Editar",
                component: EditarComercio,
              },
            ],
          },

          {
            path: "/nuevo-comercio/",
            label: "Nuevo",
            component: NuevoCliente,
          },
          {
            path: "/modificar/",
            label: "Roles de usuarios",
            component: NotFound,
          },
        ],
      },
      //destacado
      {
        path: "/destacar-propietario/",
        label: "Destacar Propietario",
        component: ListaDestacado,
      },

      //Colaboradores
      {
        path: "/colaboradores/",
        label: "Colaboradores",
        component: Colaboradores,
        routes: [
          {
            path: "/nuevo-colaborador/",
            label: "Nuevo colaborador",
            component: NuevoColaborador,
          },
          {
            path: "/lista-colaboradores/",
            label: "Lista de colaborador",
            component: ListaColaboradores,
            routes: [
              {
                path: "/editar-colaborador/",
                label: "Editar colaborador",
                component: EditarColaborador,
              },
            ],
          },
          {
            path: "/lista-tipo-colaborador/",
            label: "Lista de tipo colaborador",
            component: ListaTipoColaborador,
            routes: [
              {
                path: "/editar-tipo-colaborador/",
                label: "Editar tipo colaborador",
                component: EditarTipoColaborador,
              },
            ],
          },
          {
            path: "/nuevo-tipo-colaborador/",
            label: "Nuevo tipo colaborador",
            component: NuevoTipoColaborador,
          },
        ],
      },
      //pedidos
      {
        path: "/gestionar-pedidos/",
        label: "Gestionar pedidos",
        component: Pedidos,
        routes: [
          {
            path: "/lista-pedidos/",
            label: "Lista",
            component: ListaPedidos,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle",
                component: DetallePedido,
              },
            ],
          },
        ],
      },

      //usuarios
      {
        path: "/gestionar-usuarios/",
        label: "Gestionar usuarios",
        component: Pedidos,
        routes: [
          {
            path: "/lista-usuarios/",
            label: "Lista",
            component: ListaUsuarios,
            routes: [
              {
                path: "/editar-usuario/",
                label: "Detalle",
                component: EditarUsuario,
              },
              {
                path: "/nuevo-usuario/",
                label: "Nuevo Usuario",
                component: NuevoUsuario,
              },
            ],
          },
        ],
      },

      //ofertas
      {
        path: "/gestionar-ofertas/",
        label: "Gestionar ofertas",
        component: Oferta,
        routes: [
          {
            path: "/nueva-oferta/",
            label: "Nuevo",
            component: NuevaOferta,
          },
          {
            path: "/lista-ofertas/",
            label: "Lista",
            component: ListaOfertas,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle",
                component: DetalleOferta,
              },
              {
                path: "/editar/",
                label: "Editar",
                component: EditarOferta,
              },
            ],
          },

          {
            path: "/nuevo-mercado/",
            label: "Nuevo Mercado",
            component: NuevoMercado,
          },
          {
            path: "/lista-mercado/",
            label: "Lista Mercado",
            component: ListaMercado,
            routes: [
              {
                path: "/editar-mercado/",
                label: "Editar Mercado",
                component: EditarMercado,
              },
            ],
          },
        ],
      },

      //motivo cancelacion
      {
        path: "/gestionar-motivo/",
        label: "Gestionar Motivo Cancelación",
        component: MotivoCancelacion,
        routes: [
          {
            path: "/nuevo-motivo-usuario/",
            label: "Nuevo Motivo por Usuario",
            component: NuevoMotivoUsuario,
          },
          {
            path: "/lista-motivo-usuario/",
            label: "Lista Motivo por Usuario",
            component: ListaMotivoUsuario,
            routes: [
              {
                path: "/editar-motivo-usaurio/",
                label: "Editar",
                component: EditarMotivoUsuario,
              },
            ],
          },

          {
            path: "/nuevo-motivo-comercio/",
            label: "Nuevo Motivo por Comercio",
            component: NuevoMotivoComercio,
          },
          {
            path: "/lista-motivo-comercio/",
            label: "Lista Motivo por Comercio",
            component: ListaMotivoComercio,
            routes: [
              {
                path: "/editar-motivo-comercio/",
                label: "Editar",
                component: EditarMotivoComercio,
              },
            ],
          },
        ],
      },

      //banners
      {
        path: "/gestionar-banners/",
        label: "Gestionar Banners",
        component: Banners,
        routes: [
          {
            path: "/nuevo-banners/",
            label: "Nuevo",
            component: NuevoBanner,
          },
          {
            path: "/lista-banners/",
            label: "Lista",
            component: ListaBanner,
            routes: [
              {
                path: "/editar/",
                label: "Editar",
                component: EditarBanner,
              },
            ],
          },
        ],
      },

      //visitas
      {
        path: "/gestionar-visitas/",
        label: "Gestionar visitas",
        component: Visitas,
        routes: [
          {
            path: "/nueva-visita/",
            label: "Nueva visita",
            component: NuevaVisita,
          },
          {
            path: "/lista-visitas/",
            label: "Lista",
            component: ListaVisitas,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle de la visita",
                component: DetalleVisita,
              },
            ],
          },
          {
            path: "/clientes/",
            label: "Clientes",
            component: ListaClientesVisitas,
            routes: [
              {
                path: "/historicos/",
                label: "Historicos",
                component: ListaHistoricoCliente,
              },
            ],
          },
        ],
      },

      //Punto de Interes
      {
        path: "/nuevo-recorrido/",
        label: "Nuevo Recorrido",
        component: NuevoRecorrido,
      },

      {
        path: "/gestionar-tramos",
        label: "Gestionar tramos",
        component: HistoricoTramos,
        routes: [
          {
            path: "/punteros",
            label: "Punteros",
            component: CrearTramosPunteros,
          },
          {
            path: "/vendedores",
            label: "Vendedores",
            component: CrearTramosVendedores,
          },
        ],
      },

      {
        path: "/istar-Familia/",
        label: "Listar Familia",
        component: ListarFamilia,
      },

      {
        path: "/listar-Puntos-de-Interes/",
        label: "Listar Puntos de Interes",
        component: ListarPuntoDeInteres,
      },

      {
        path: "/istar-Tipo/",
        label: "Listar Tipo",
        component: ListarTipo,
      },
      {
        path: "/listar-SubTipo/",
        label: "Listar SubTipo",
        component: ListarSubTipo,
      },
      {
        path: "/listar-Grupo-Informacion/",
        label: "Listar Grupo de Informacion",
        component: ListarGrupoInformacion,
      },

      {
        path: "/nueva-familia/",
        label: "Nuevo tipo de Familia",
        component: NuevaFamiilia,
      },
      {
        path: "/nuevo-tipo/",
        label: "Nuevo tipo",
        component: NuevoTipo,
      },
      {
        path: "/nuevo-subtipo/",
        label: "Nuevo SubTipo",
        component: NuevoSubTipo,
      },
      {
        path: "/nuevo-grupo-informacion/",
        label: "Nuevo Grupo de Informacion",
        component: NuevoGrupoDeInformacion,
      },
      {
        path: "/nuevo-tipo-grupo-informacion/",
        label: "Nuevo Tipo de Grupo de Informacion",
        component: NuevoTipoGrupoDeInformacion,
      },

      {
        path: "/getAll-Punto-Interes-maps/",
        component: GetAllPuntoDeInteresMaps,
      },
      {
        path: "/editar-punto-de-interes/",
        label: "Editar Punto de Interes",
        component: EditarPuntoDeInteres,
      },
      {
        path: "/lista-puntos-interes/",
        label: "Lista",
        component: ListaPuntoDeInteres,
        routes: [
          {
            path: "/detalle/",
            label: "Detalle del Recorrido",
            component: DetalleDeRecorrido,
          },
        ],
      },
      //etapas estados
      {
        path: "/gestionar-etapas-estados/",
        label: "Gestionar estapas y estados",
        component: EtapasEstados,
        routes: [
          {
            path: "/nueva-etapa/",
            label: "Nuevo",
            component: NuevaEtapa,
          },
          {
            path: "/lista-etapas/",
            label: "Lista",
            component: LsitarEtapas,
            routes: [
              { path: "/detalle/", label: "Detalle", component: DetalleEtapa },
              { path: "/editar/", label: "Editar", component: EditarEtapa },
            ],
          },
        ],
      },

      //Cartera
      {
        path: "/gestionar-cartera/",
        label: "Gestionar carteras",
        component: Cartera,
        routes: [
          {
            path: "/nueva-cartera/",
            label: "Nuevo",
            component: NuevaCartera,
          },
          {
            path: "/lista-carteras/",
            label: "Lista",
            component: ListaCarteras,
            routes: [
              {
                path: "/detalle/",
                label: "Detalle",
                component: DetalleCartera,
              },
            ],
          },
          {
            path: "/comercios-sin-carteras/",
            label: "Comercos sin carteras",
            component: ListarComercioSinCarteras,
          },
        ],
      },

      {
        path: "/categoria/",
        label: "Categorías",
        component: Categoria,
        routes: [
          {
            path: "/nueva-categoria/",
            label: "Nuevo",
            component: NuevaCategoria,
          },

          {
            path: "/lista-categorias/",
            label: "Lista",
            component: ListarCategorias,
            routes: [
              {
                path: "/editar-categoria/",
                label: "Editar",
                component: EditarCategoria,
              },
            ],
          },
        ],
      },
      {
        path: "/forma-de-pago/",
        label: "Forma de pago",
        component: FormaDePago,
        routes: [
          {
            path: "/nueva-forma-de-pago/",
            label: "Nuevo",
            component: NuevaFormaDePago,
          },
          {
            path: "/lista-forma-de-pago/",
            label: "Lista",
            component: ListarFormaDePago,
            routes: [
              {
                path: "/editar-forma-de-pago/",
                label: "Editar",
                component: EditarFormaDePago,
              },
            ],
          },
        ],
      },
      {
        path: "/tipo-pedido/",
        label: "Tipo pedido",
        component: TipoPedido,
        routes: [
          {
            path: "/nuevo-tipo-pedido/",
            label: "Nuevo",
            component: NuevoTipoPedido,
          },
          {
            path: "/lista-tipo-pedido/",
            label: "Lista",
            component: ListarTipoPedido,
            routes: [
              {
                path: "/editar-tipo-pedido/",
                label: "Editar",
                component: EditarTipoPedido,
              },
            ],
          },
        ],
      },

      //TIPOS DE FAMILIA

      {
        path: "/tipo-Familia/",
        label: "Familia",
        component: TipoPedido,
        routes: [
          {
            path: "/nuevo-tipo-familia/",
            label: "Nuevo",
            component: NuevoTipoPedido,
          },
          {
            path: "/lista-tipo-familia/",
            label: "Lista",
            component: ListarTipoPedido,
            routes: [
              {
                path: "/editar-tipo-familia/",
                label: "Editar",
                component: EditarTipoPedido,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "*",
    label: "Error en la página",
    component: NotFound,
  },
]
