import React, { useEffect, useState } from "react"
import { MapContainer, TileLayer, Polygon, Tooltip, Marker } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import axios from "../../utils/axios"
import { alertWarningError } from "../../components/Notificaciones"
import { Grid, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { iconPerson } from "../../components/IconMap"
import dayjs from "dayjs"

const HistoricoTramos = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  const [typeUser, setTypeUser] = useState("Puntero")
  const [statusTramo, setStatusTramo] = useState()

  useEffect(() => {
    getTramos(null, typeUser)
  }, [])

  const getTramos = async (statusEnum, typeEnum = "Puntero") => {
    setIsLoading(true)
    let url = "tramos/get-all/"
    try {
      const response = await axios.get(url, {
        params: { status: statusEnum, type: typeEnum },
      })

      let status = response.status
      if (status === 200) {
        const colaboradores = response.data
        setData(
          colaboradores.map((item) => {
            return { ...item, color: generarColorHexAleatorio() }
          }),
        )

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  function generarColorHexAleatorio() {
    // Generar un número aleatorio entre 0 y 16777215 (hexadecimal FFFFFF)
    const numeroAleatorio = Math.floor(Math.random() * 16777215)
    // Convertir el número aleatorio a formato hexadecimal y asegurarse de que tenga 6 dígitos
    const colorHex = `#${numeroAleatorio.toString(16).padStart(6, "0")}`
    return colorHex
  }

  const handleChangeValues = (status, type) => {
    setStatusTramo(status)
    setTypeUser(type)

    getTramos(status, type)
  }

  return (
    <>
      <Grid
        style={{ marginBottom: 20 }}
        container
        direction="row"
        justify="flex-start"
        alignContent="center"
      >
        <Grid sm={4}>
          <Autocomplete
            id="tipoPuntero"
            size="small"
            value={typeUser}
            onChange={(e, val) => handleChangeValues(statusTramo, val)}
            options={["Puntero", "Vendedor"]}
            getOptionLabel={(option) => option}
            renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Seleccione tipo de usuario"
                name="tipoPuntero"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid sm={4} style={{ marginLeft: 10 }}>
          <Autocomplete
            id="tipoPuntero"
            size="small"
            value={statusTramo}
            onChange={(e, val) => handleChangeValues(val, typeUser)}
            options={["Activo", "Inactivo", "Finalizados"]}
            getOptionLabel={(option) => option}
            renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Seleccione un estado"
                name="tipoPuntero"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <MapContainer
        center={[-25.338719, -57.487774]}
        zoom={16}
        style={{ height: "800px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://carto.com/">CARTO</a>'
        />
        {typeUser === "Puntero"
          ? data.map((item, index) => {
              return (
                <Polygon
                  key={index}
                  positions={JSON.parse(item.lat_long)}
                  pathOptions={{ color: item.color }}
                >
                  <Tooltip sticky>
                    {`Recorrido del usuario - ${item.full_name} en fecha ${dayjs(item.assigned_date).format("DD-MM-YYYY")}`}
                  </Tooltip>
                </Polygon>
              )
            })
          : data.map((item) => {
              return JSON.parse(item.lat_long).map((val, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: val[0], lng: val[1] }}
                    icon={iconPerson}
                  >
                    <Tooltip sticky>
                      {`Puntos del usuario - ${item.full_name} en fecha ${dayjs(item.assigned_date).format("DD-MM-YYYY")}`}
                    </Tooltip>
                  </Marker>
                )
              })
            })}
      </MapContainer>
    </>
  )
}

export default HistoricoTramos
