import L from "leaflet"
import LocationPin from "../assets/images/location-pin.svg"

const iconPerson = new L.Icon({
  iconUrl: LocationPin,
  iconRetinaUrl: LocationPin,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(50, 50),
})

export { iconPerson }
