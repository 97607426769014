import React, { useEffect, useRef, useState } from "react"
import { useContext } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import UserContext from "../../utils/user/UserContext"
import { useStyles } from "../../assets/styles/CustomStyles"
import AccesoDenegado from "../../components/AccesoDenegado"
import { MapContainer, TileLayer, Tooltip, Marker, useMap } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import { iconPerson } from "../../components/IconMap"
import { iconUserLocation } from "../../components/CurrentIconMap"
import SaveIcon from "@material-ui/icons/Save"
import { BotonVerde } from "../../assets/styles/StyledButtons"
import { CardActions, Grid, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import axios from "../../utils/axios"
import { alertWarningError } from "../../components/Notificaciones"
import { iconSelectedMap } from "../../components/IconSelectedMap"

export function CrearTramosVendedores() {
  const userContext = useContext(UserContext)
  const history = useHistory()

  const [punteroData, setPunteroData] = useState()
  const [assignedDate, setAssignedDate] = useState()

  const [markersPointer, setMarkersPointer] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })

  document.addEventListener("keypress", (event) => {
    if (event.ctrlKey && event.code === "KeyZ") {
      const temp = [...markersPointer]
      temp.pop()

      setMarkersPointer(temp)
    }
  })

  useEffect(() => {
    getColaboradores()
  }, [])

  const getColaboradores = async (props) => {
    setIsLoading(true)
    let url = "colaboradores/colaborador-listar/"
    try {
      const response = await axios.post(url, {
        idColaborador: null,
      })
      let status = response.status
      if (status === 200) {
        const colaboradores = response.data
        setData({ ...data, content: colaboradores?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const [comerciosData, setComerciosData] = useState({ content: [] })

  useEffect(() => {
    getComercios()
  }, [])

  const getComercios = async (props) => {
    setIsLoading(true)
    let url = "comercios/comercio-listar/0/ACTIVAR"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const clientes = response.data
        setComerciosData({ ...data, content: clientes?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const onSelectPuntero = (e, value) => {
    const selected = data.content.find((element) => element.id === value.id)

    setPunteroData(selected)
  }

  const [listedComercios, setListedComercios] = useState([])

  const handleComerciosRecorrer = ({ lat, lng }) => {
    const comercio = comerciosData.content.find(
      (item) => item.latitud === lat.toString() && item.longitud === lng.toString(),
    )

    if (!comercio) return

    const temp = [...listedComercios]
    temp.push(comercio)
    setListedComercios(temp)
  }

  const handleComerciosRemover = ({ lat, lng }) => {
    const comercio = comerciosData.content.findIndex(
      (item) => item.latitud === lat.toString() && item.longitud === lng.toString(),
    )

    const temp = [...listedComercios]
    temp.splice(comercio, 1)

    setListedComercios(temp)
  }

  const savePuntoDeRecorrido = async () => {
    if (!punteroData || !assignedDate || listedComercios.length < 1) {
      alertWarningError({
        data: { level: "WARNING", message: "Faltan datos por cargar!" },
      })
      return
    }

    const data = {
      puntero_id: punteroData.id,
      latLong: listedComercios.map((item) => [item.latitud, item.longitud]),
      id_comercios: listedComercios.map((item) => {
        return item.id_cliente
      }),
      type: "Vendedor",
      status: "Activo",
      assigned_date: assignedDate,
    }

    setIsLoading(true)
    let url = "tramos/crear-tramo/"
    try {
      const response = await axios.post(url, data)
      let status = response.status
      if (status === 201) {
        history.push("/gestionar-tramos")

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <CardActions style={{ marginBottom: 20 }}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignContent="center"
              spacing={2}
            >
              <Grid item sm={5}>
                <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                  <p>Comercios:</p>
                  <div>
                    {listedComercios.map((item, index) => {
                      return <p>{`#${index + 1} - ${item.nombre}`}</p>
                    })}
                  </div>
                </div>
              </Grid>

              <Grid item sm={5}>
                <Autocomplete
                  id="tipoPuntero"
                  size="small"
                  value={punteroData || ""}
                  onChange={onSelectPuntero}
                  options={data.content}
                  getOptionLabel={(option) =>
                    option.nombre
                      ? `${option?.nombre} ${option?.apellido} - ${option.tipoUsuario}`
                      : ""
                  }
                  renderOption={(option) => (
                    <React.Fragment>{`${option?.nombre} ${option?.apellido} - ${option.tipoUsuario}`}</React.Fragment>
                  )}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Seleccione un vendedor para el recorrido"
                      name="tipoPuntero"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  id="fecha"
                  name="fecha"
                  label="Fecha asignada"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  value={assignedDate}
                  onChange={(val) => setAssignedDate(val.target.value)}
                />
              </Grid>
              <Grid item>
                <BotonVerde
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<SaveIcon />}
                  loading={isLoading}
                  onClick={savePuntoDeRecorrido}
                >
                  Guardar
                </BotonVerde>{" "}
              </Grid>
            </Grid>
          </CardActions>

          <MapContainer
            center={[-25.338719, -57.487774]}
            zoom={16}
            style={{ height: "800px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://carto.com/">CARTO</a>'
            />
            <LocationMarker />
            {comerciosData.content.length > 0 &&
              comerciosData.content.map((item, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: item.latitud, lng: item.longitud }}
                    icon={
                      listedComercios.find(
                        (val) =>
                          val.latitud === item.latitud.toString() &&
                          val.longitud === item.longitud.toString(),
                      )
                        ? iconSelectedMap
                        : iconPerson
                    }
                    data={item.id}
                    eventHandlers={{
                      click: (e) => {
                        listedComercios.find(
                          (val) =>
                            val.latitud === item.latitud.toString() &&
                            val.longitud === item.longitud.toString(),
                        )
                          ? handleComerciosRemover(e.latlng)
                          : handleComerciosRecorrer(e.latlng)
                      },
                    }}
                  >
                    <Tooltip sticky>{`Comercio - ${item.nombre}`}</Tooltip>
                  </Marker>
                )
              })}
          </MapContainer>
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}

function LocationMarker() {
  const [position, setPosition] = useState(null)

  const map = useMap()

  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng)
      map.flyTo(e.latlng, map.getZoom())
    })
  }, [map])

  return position === null ? null : (
    <Marker position={position} icon={iconUserLocation}></Marker>
  )
}
