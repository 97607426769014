import L from "leaflet"
import IconSelectedMap from "../assets/images/location-selected.svg"

const iconSelectedMap = new L.Icon({
  iconUrl: IconSelectedMap,
  iconRetinaUrl: IconSelectedMap,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(50, 50),
})

export { iconSelectedMap }
