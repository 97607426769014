import React, { useState, useEffect } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Typography } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import swal from "sweetalert"
import BackdropCustom from "../../components/BackdropCustom"
import { alertWarningError } from "../../components/Notificaciones"

const inicialValue = {
  nombre: "",
  orden: 0,
}

export default function NuevaEtapa() {
  const history = useHistory()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [etapa, setEtapa] = useState(inicialValue)

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "etapas/etapa-crear"
    try {
      const response = await axios.post(url, etapa)
      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...etapa, nombre: event.target.value }
    setEtapa(copyInput)
  }

  const handleChangeOrden = (event) => {
    let copyInput = { ...etapa, orden: Number(event.target.value) }
    setEtapa(copyInput)
  }

  return (
    <>
      <BackdropCustom open={isLoading} />
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid item sm={12} md={7} lg={7} xl={7}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="descripcion"
                name="descripcion"
                value={etapa.nombre}
                onChange={(value) => handleChangeNombre(value)}
                label="Nombre de la etapa"
                type="text"
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

            <Grid item sm={12} md={3} lg={3} xl={3}>
              <TextField
                size="small"
                variant="outlined"
                id="orden"
                name="orden"
                value={etapa.orden}
                onChange={(value) => handleChangeOrden(value)}
                label="Orden"
                type="number"
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={etapa.nombre === "" ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
