import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import UserContext from "../../utils/user/UserContext"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const inicialValue = {
  idComercio: 0,
  nombre: "",
  nombreFantasia: "kk",
  callePrincipal: "",
  calleSecundaria: "",
  idCiudad: 0,
  idBarrio: 0,
  latitud: "",
  longitud: "",
  propietario: "",
  razonSocial: "",
  ruc: "",
  numeroContacto: "",
  whatsapp: "",
  cantidadPersonal: 0,
  tieneDelivery: "",
  tipoDelivery: "",
  tipoPartner: "",
  friccionPedidosYa: null,
  friccionMonchis: null,
  facebook: "",
  instagram: "",
  twitter: "",
  nis: "",
  servicioAguateria: "",
  tipoEmpresa: "",
  antiguedadAnho: "",
  antiguedadMes: "",
  prestamo: false,
  productoNecesita: "",
  iva: false,
  ivaAnho: 0,
  ivaMes: 0,
  patente: false,
  patenteAnho: 0,
  patenteMes: 0,
  canon: false,
  canonAnho: 0,
  canonMes: 0,
  datosPropietario: "",
  datosEmpleado: "",
  scoring: "",
  estadoFormulario: "",
}

export default function EditarComercio() {
  const history = useHistory()
  const classes = useStyles()
  const dataProps = history.location.state
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCiudad, setIsLoadingCiudad] = useState(false)
  const [isLoadingBarrio, setIsLoadingBarrio] = useState(false)

  const [cliente, setCliente] = useState(inicialValue)
  const [tipoEmpresa, setTipoEmpresa] = useState({})
  const [tipoDelivery, setTipoDelivery] = useState({})
  const [tieneDelivery, setTieneDelivery] = useState({})
  const [estadoFormulario, setEstadoFormulario] = useState({})
  const [barrio, setBarrio] = useState({})
  const [barrioList, setBarrioList] = useState({ content: [] })
  const [ciudad, setCiudad] = useState({})
  const [ciudadList, setCiudadList] = useState({ content: [] })
  const [fotoPerfil, setFotoPerfil] = useState({})
  const [fotoPrinc, setFotoPrinc] = useState({})

  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.id_cliente > 0) {
      getCiudad()

      let copyInput = {
        ...cliente,
        idComercio: dataProps?.id_cliente,
        nombre: dataProps?.nombre,
        nombreFantasia: dataProps?.nomb_fantasia,
        callePrincipal: dataProps?.callePrincipal,
        calleSecundaria: dataProps?.calleSecundaria,
        idCiudad: dataProps?.idCiudad,
        idBarrio: dataProps?.idBarrio,
        latitud: dataProps?.latitud,
        longitud: dataProps?.longitud,
        propietario: dataProps?.nomb_encargado,
        razonSocial: dataProps?.razon_social,
        ruc: dataProps?.ruc,
        numeroContacto: dataProps?.nro_contacto,
        whatsapp: dataProps?.whatsapp,
        cantidadPersonal: dataProps?.cantidadPersonal,
        tieneDelivery: dataProps?.tieneDelivery,
        tipoDelivery: dataProps?.tipoDelivery,
        tipoPartner: dataProps?.tipoPartner,
        facebook: dataProps?.facebook,
        instagram: dataProps?.instagram,
        twitter: dataProps?.twitter,
        nis: dataProps?.nis,
        servicioAguateria: dataProps?.servicioAguateria,
        tipoEmpresa: dataProps?.tipoEmpresa,
        antiguedadAnho: dataProps?.antiguedadAnho,
        antiguedadMes: dataProps?.antiguedadMes,
        prestamo: dataProps?.prestamo === 1 ? true : false,
        productoNecesita: dataProps?.productoNecesita,
        iva: dataProps?.iva === 1 ? true : false,
        ivaAnho: dataProps?.ivaAnho,
        ivaMes: dataProps?.ivaMes,
        patente: dataProps?.patente === 1 ? true : false,
        patenteAnho: dataProps?.patenteAnho,
        patenteMes: dataProps?.patenteMes,
        canon: dataProps?.canon === 1 ? true : false,
        canonAnho: dataProps?.canonAnho,
        canonMes: dataProps?.canonMes,
        datosPropietario: dataProps?.datosPropietario,
        datosEmpleado: dataProps?.datosEmpleados,
        scoring: dataProps?.scoring,
        estadoFormulario: dataProps?.estadoFormulario,
      }
      setCliente(copyInput)
      console.log("cliente info", copyInput)
      setTipoEmpresa(dataProps?.tipoEmpresa)
      setTipoDelivery(dataProps?.tipoDelivery)
      setTieneDelivery(dataProps?.tieneDelivery)
      setEstadoFormulario(dataProps?.estadoFormulario)
      setFotoPrinc(dataProps?.urlFoto)
      setCiudad({
        id_ciudad: dataProps?.idCiudad,
        nombre: dataProps?.ciudad,
      })

      setBarrio({
        id_ciudad: dataProps?.idCiudad,
        id_barrio: dataProps?.idBarrio,
        nombre: dataProps?.barrio,
      })
    }
  }, [])

  const getCiudad = async () => {
    setIsLoadingCiudad(true)
    let url = "common/ciudad-listar"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const ciudad = response.data
        setCiudadList({ ...ciudadList, content: ciudad?.result })

        setIsLoadingCiudad(false)
      }
    } catch (error) {
      setIsLoadingCiudad(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getBarrio = async (props) => {
    setIsLoadingBarrio(true)
    let url = "common/barrio-listar/" + props
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const barrio = response.data
        setBarrioList({ ...barrioList, content: barrio?.result })

        setIsLoadingBarrio(false)
      }
    } catch (error) {
      setIsLoadingBarrio(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const onSelectCiudad = (e, value) => {
    console.log(value)
    if (value && value?.id_ciudad !== ciudad?.id_ciudad) {
      setCiudad(value)
      let copyInput = { ...cliente, idCiudad: value?.id_ciudad }
      setCliente(copyInput)
    }

    if (value === null) {
      setCiudad({})
      let copyInput = { ...cliente, idCiudad: 0 }
      setCliente(copyInput)
    }

    if (value?.id_ciudad) {
      getBarrio(value?.id_ciudad)
    } else {
      setCiudad({})
      setBarrio({})
      let copyInput = { ...cliente, idCiudad: 0, idBarrio: 0 }
      setCliente(copyInput)

      setBarrioList({ ...barrioList, content: [] })
    }
  }

  const onSelectBarrio = (e, value) => {
    console.log(value)
    if (value && value?.id_barrio !== barrio?.id_barrio) {
      setBarrio(value)

      let copyInput = { ...cliente, idBarrio: value?.id_barrio }
      setCliente(copyInput)
    }

    if (value === null) {
      let copyInput = { ...cliente, idBarrio: 0 }
      setCliente(copyInput)
      setBarrio({})
    }
  }

  const onSelectEstadoFormulario = (e, value) => {
    if (value && value !== estadoFormulario) {
      setEstadoFormulario(value)
      let copyInput = { ...cliente, estadoFormulario: value }
      setCliente(copyInput)
    }

    if (value === null) {
      setEstadoFormulario({})
      let copyInput = { ...cliente, estadoFormulario: "" }
      setCliente(copyInput)
    }
  }
  const onSelectTipoEmpresa = (e, value) => {
    if (value && value !== tipoEmpresa) {
      setTipoEmpresa(value)
      let copyInput = { ...cliente, tipoEmpresa: value }
      setCliente(copyInput)
    }

    if (value === null) {
      setTipoEmpresa({})
      let copyInput = { ...cliente, tipoEmpresa: "" }
      setCliente(copyInput)
    }
  }

  const onSelectTipoDelivery = (e, value) => {
    if (value && value !== tipoDelivery) {
      setTipoDelivery(value)

      let copyInput = { ...cliente, tipoDelivery: value }
      setCliente(copyInput)
    }

    if (value === null) {
      setTipoDelivery({})
      let copyInput = { ...cliente, tipoDelivery: "" }
      setCliente(copyInput)
    }
  }

  const onSelectTieneDelivery = (e, value) => {
    if (value && value !== tieneDelivery) {
      setTieneDelivery(value)

      let copyInput = { ...cliente, tieneDelivery: value }
      setCliente(copyInput)
    }

    if (value === null) {
      setTieneDelivery({})
      let copyInput = { ...cliente, tieneDelivery: "" }
      setCliente(copyInput)
    }
  }

  const uploaderProps = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader()
      console.log(file)

      const duplicado = fotoPerfil?.file?.uid === file.uid

      console.log(duplicado)
      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file)
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {
          let base64 = reader.result.split(";base64,")
          let documentoBase64 = base64[1]
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          }

          console.log(fotoPerfil)
          setFotoPerfil(imagen)
        }

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          console.log("couldn't read the file")
        }
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      console.log("onError", err)
    },
    capture: "josue",
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "comercios/comercio-actualizar-admin"
    console.log(cliente)
    try {
      const response = await axios.post(url, cliente)
      let status = response.status
      if (status === 200) {
        if (Object.keys(fotoPerfil).length === 0) {
          setIsLoading(false)
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.goBack()
        } else {
          handleEnviarFotoPerfil(cliente.idComercio)
        }
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const handleEnviarFotoPerfil = async (props) => {
    const formData = new FormData()
    formData.append("imgs", fotoPerfil?.file)
    try {
      const response = await axios.post(`comercios/foto/${props}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...cliente, nombre: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeNombreFantasia = (event) => {
    let copyInput = { ...cliente, nombreFantasia: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeCallePrincipal = (event) => {
    let copyInput = { ...cliente, callePrincipal: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeCalleSecundaria = (event) => {
    let copyInput = { ...cliente, calleSecundaria: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeLatitud = (event) => {
    let copyInput = { ...cliente, latitud: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeLongitud = (event) => {
    let copyInput = { ...cliente, longitud: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeNombrePropietario = (event) => {
    let copyInput = { ...cliente, propietario: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeRazonSocial = (event) => {
    let copyInput = { ...cliente, razonSocial: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeRUC = (event) => {
    let copyInput = { ...cliente, ruc: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeNumeroContacto = (event) => {
    let copyInput = { ...cliente, numeroContacto: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeWhatsapp = (event) => {
    let copyInput = { ...cliente, whatsapp: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeCantidadPersonal = (event) => {
    let copyInput = { ...cliente, cantidadPersonal: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangeTipoPartner = (event) => {
    let copyInput = { ...cliente, tipoPartner: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeFacebook = (event) => {
    let copyInput = { ...cliente, facebook: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeInstagram = (event) => {
    let copyInput = { ...cliente, instagram: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeTwitter = (event) => {
    let copyInput = { ...cliente, twitter: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeNis = (event) => {
    let copyInput = { ...cliente, nis: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeServicioAguateria = (event) => {
    let copyInput = { ...cliente, servicioAguateria: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeAntiguedadAnio = (event) => {
    let copyInput = { ...cliente, antiguedadAnho: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangeAntiguedadMes = (event) => {
    let copyInput = { ...cliente, antiguedadMes: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangePrestamo = (event) => {
    let copyInput = { ...cliente, prestamo: event.target.checked }
    setCliente(copyInput)
  }
  const handleChangeProductoNecesita = (event) => {
    let copyInput = { ...cliente, productoNecesita: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeIVA = (event) => {
    let copyInput = { ...cliente, iva: event.target.checked }
    setCliente(copyInput)
  }

  const handleChangeIvaAnio = (event) => {
    let copyInput = { ...cliente, ivaAnho: Number(event.target.value) }
    setCliente(copyInput)
  }
  const handleChangeIvaMes = (event) => {
    let copyInput = { ...cliente, ivaMes: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangePatente = (event) => {
    let copyInput = { ...cliente, patente: event.target.checked }
    setCliente(copyInput)
  }

  const handleChangePatenteAnio = (event) => {
    let copyInput = { ...cliente, patenteAnho: Number(event.target.value) }
    setCliente(copyInput)
  }
  const handleChangePatenteMes = (event) => {
    let copyInput = { ...cliente, patenteMes: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangeCanon = (event) => {
    let copyInput = { ...cliente, canon: event.target.checked }
    setCliente(copyInput)
  }

  const handleChangeCanonAnio = (event) => {
    let copyInput = { ...cliente, canonAnho: Number(event.target.value) }
    setCliente(copyInput)
  }
  const handleChangeCanonMes = (event) => {
    let copyInput = { ...cliente, canonMes: Number(event.target.value) }
    setCliente(copyInput)
  }
  const handleChangeDatoPropietario = (event) => {
    let copyInput = { ...cliente, datosPropietario: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeDatoEmpleado = (event) => {
    let copyInput = { ...cliente, datosEmpleado: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeScoring = (event) => {
    let copyInput = { ...cliente, scoring: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeEstadoFormulario = (event) => {
    let copyInput = { ...cliente, estadoFormulario: event.target.value }
    setCliente(copyInput)
  }

  const handleEliminarImagen = (event) => {
    event.preventDefault()
    setFotoPerfil({})
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Grid container direction="row" justify="flex-start" spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              style={{ textAlign: "center", paddingTop: 30 }}
            >
              {fotoPrinc === null ? (
                Object.keys(fotoPerfil).length === 0 ? (
                  <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                    <Avatar alt="Foto Perfil" style={{ width: 100, height: 100 }}>
                      <PhotoCameraIcon fontSize="large" />
                    </Avatar>
                  </Box>
                ) : (
                  <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <Tooltip title="Eliminar imagen" aria-label="eleminar">
                          <IconButton
                            edge="end"
                            variant="contained"
                            aria-label="delete"
                            onClick={(event) => handleEliminarImagen(event)}
                          >
                            <DeleteIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      }
                    >
                      <Avatar
                        alt="Foto Perfil"
                        src={`data:${fotoPerfil?.type};base64,${fotoPerfil?.data}`}
                        style={{ width: 100, height: 100 }}
                      />
                    </Badge>
                  </Box>
                )
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => setFotoPrinc(null)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      alt="Foto categoría"
                      src={fotoPrinc}
                      style={{ width: 100, height: 100 }}
                    />
                  </Badge>
                </Box>
              )}

              <Upload {...uploaderProps}>
                <Button
                  size="small"
                  //  startIcon={<PhotoCameraIcon />}
                  //startIcon={PublishIcon}
                  variant="contained"
                  color="primary"
                  children="Foto perfil"
                />
              </Upload>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={cliente.nombre}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="callePrincipal"
                    name="callePrincipal"
                    label="Calle principal"
                    value={cliente.callePrincipal}
                    onChange={(value) => handleChangeCallePrincipal(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="calleSrincipal"
                    name="calleSrincipal"
                    label="Calle Secundaria"
                    value={cliente.calleSecundaria}
                    onChange={(value) => handleChangeCalleSecundaria(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="ciudad"
                    size="small"
                    value={ciudad || ""}
                    onChange={onSelectCiudad}
                    options={ciudadList?.content}
                    getOptionLabel={(option) => (option.nombre ? option.nombre : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option?.nombre}</React.Fragment>
                    )}
                    loading={isLoadingCiudad}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Ciudad"
                        name="ciudad"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingCiudad ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="barrio"
                    size="small"
                    value={barrio || ""}
                    onChange={onSelectBarrio}
                    options={barrioList?.content}
                    getOptionLabel={(option) =>
                      option?.nombre ? option?.nombre : ""
                    }
                    renderOption={(option) => (
                      <React.Fragment>{option?.nombre}</React.Fragment>
                    )}
                    loading={isLoadingBarrio}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Barrio"
                        name="barrio"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingBarrio ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    value={cliente.latitud}
                    onChange={(value) => handleChangeLatitud(value)}
                    variant="outlined"
                    id="latitud"
                    name="latitud"
                    label="Latitud"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    value={cliente.longitud}
                    onChange={(value) => handleChangeLongitud(value)}
                    variant="outlined"
                    id="longitud"
                    name="longitud"
                    label="Longitud"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    value={cliente.propietario}
                    onChange={(value) => handleChangeNombrePropietario(value)}
                    variant="outlined"
                    id="propietario"
                    name="propietario"
                    label="Nombre propietario"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    value={cliente.razonSocial}
                    onChange={(value) => handleChangeRazonSocial(value)}
                    variant="outlined"
                    id="razonSocial"
                    name="razonSocial"
                    label="Razón Social"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    value={cliente.ruc}
                    onChange={(value) => handleChangeRUC(value)}
                    variant="outlined"
                    id="ruc"
                    name="ruc"
                    label="RUC"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="NroContacto"
                    name="NroContacto"
                    label="Nro Contacto"
                    value={cliente.numeroContacto}
                    onChange={(value) => handleChangeNumeroContacto(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="Whatsapp"
                    name="Whatsapp"
                    label="Whatsapp"
                    value={cliente.whatsapp}
                    onChange={(value) => handleChangeWhatsapp(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="cantidadPersonal"
                    name="cantidadPersonal"
                    label="Cantidad Personal"
                    value={cliente.cantidadPersonal}
                    onChange={(value) => handleChangeCantidadPersonal(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    id="tieneDelivery"
                    size="small"
                    value={tieneDelivery || ""}
                    onChange={onSelectTieneDelivery}
                    options={["SI", "NO"]}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tiene Delivery"
                        name="tieneDelivery"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    id="tipoDelivery"
                    size="small"
                    value={tipoDelivery || ""}
                    onChange={onSelectTipoDelivery}
                    options={["PROPIO", "TERCIARIZADO"]}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tipo Delivery"
                        name="tipoDelivery"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="tipoPartner"
                    name="tipoPartner"
                    label="Tipo Partner"
                    value={cliente.tipoPartner}
                    onChange={(value) => handleChangeTipoPartner(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                {/* <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="friccionPedidosYa"
                    name="friccionPedidosYa"
                    label="Fricción PedidosYa"
                    value={cliente.friccionPedidosYa}
                    onChange={(value) => handleChangeFriccionPedidoYa(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="friccionMonchis"
                    name="friccionMonchis"
                    label="Fricción Monchis"
                    value={cliente.friccionMonchis}
                    onChange={(value) => handleChangeFriccionMonchis(value)}
                    type="text"
                    fullWidth
                  />
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="facebook"
                    name="facebook"
                    label="Facebook"
                    value={cliente.facebook}
                    onChange={(value) => handleChangeFacebook(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="instagram"
                    name="instagram"
                    label="Instagram"
                    value={cliente.instagram}
                    onChange={(value) => handleChangeInstagram(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="twitter"
                    name="Tiktok"
                    label="Tiktok"
                    value={cliente.twitter}
                    onChange={(value) => handleChangeTwitter(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="nisAnde"
                    name="nisAnde"
                    label="NIS ANDE"
                    value={cliente.nis}
                    onChange={(value) => handleChangeNis(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="servicioAguateria"
                    name="servicioAguateria"
                    label="Servicio Aguateria"
                    value={cliente.servicioAguateria}
                    onChange={(value) => handleChangeServicioAguateria(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="tipoEmpresa"
                    size="small"
                    value={tipoEmpresa || ""}
                    onChange={onSelectTipoEmpresa}
                    options={["Unipersonal", "SRL", "SA", "SIN RUC"]}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tipo empresa"
                        name="tipoEmpresa"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="antiguedadAnho"
                    name="antiguedadAnho"
                    label="Antiguedad año"
                    value={cliente.antiguedadAnho}
                    onChange={(value) => handleChangeAntiguedadAnio(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="antiguedadMes"
                    name="antiguedadMes"
                    label="Antiguedad mes"
                    value={cliente.antiguedadMes}
                    onChange={(value) => handleChangeAntiguedadMes(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cliente.prestamo}
                          onChange={(value) => handleChangePrestamo(value)}
                          name="Prestamo"
                          color="primary"
                        />
                      }
                      label="Prestamo"
                    />
                  </FormGroup>
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="productoNecesita"
                    name="productoNecesita"
                    label="Producto Necesita"
                    value={cliente.productoNecesita}
                    onChange={(value) => handleChangeProductoNecesita(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cliente.iva}
                          onChange={(value) => handleChangeIVA(value)}
                          name="IVA"
                          color="primary"
                        />
                      }
                      label="IVA"
                    />
                  </FormGroup>
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="ivaMes"
                    name="ivaMes"
                    label="IVA mes"
                    value={cliente.ivaMes}
                    onChange={(value) => handleChangeIvaMes(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="ivaAnho"
                    name="ivaAnho"
                    label="IVA año"
                    value={cliente.ivaAnho}
                    onChange={(value) => handleChangeIvaAnio(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cliente.patente}
                          onChange={(value) => handleChangePatente(value)}
                          name="Patente"
                          color="primary"
                        />
                      }
                      label="Patente"
                    />
                  </FormGroup>
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="patenteMes"
                    name="patenteMes"
                    label="Patente mes"
                    value={cliente.patenteMes}
                    onChange={(value) => handleChangePatenteMes(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="patenteAnho"
                    name="patenteAnho"
                    label="Patente año"
                    value={cliente.patenteAnho}
                    onChange={(value) => handleChangePatenteAnio(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cliente.canon}
                          onChange={(value) => handleChangeCanon(value)}
                          name="Canon"
                          color="primary"
                        />
                      }
                      label="Canon"
                    />
                  </FormGroup>
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="canonMes"
                    name="canonMes"
                    label="Canon mes"
                    value={cliente.canonMes}
                    onChange={(value) => handleChangeCanonMes(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="canonAnho"
                    name="canonAnho"
                    label="Canon año"
                    value={cliente.canonAnho}
                    onChange={(value) => handleChangeCanonAnio(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="datosPropietario"
                    name="datosPropietario"
                    label="Datos Propietario"
                    value={cliente.datosPropietario}
                    onChange={(value) => handleChangeDatoPropietario(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="datosEmpleado"
                    name="datosEmpleado"
                    label="Datos Empleado"
                    value={cliente.datosEmpleado}
                    onChange={(value) => handleChangeDatoEmpleado(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="scoring"
                    name="scoring"
                    label="Scoring"
                    value={cliente.scoring}
                    onChange={(value) => handleChangeScoring(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    id="estadoFormulario"
                    size="small"
                    value={estadoFormulario || ""}
                    onChange={onSelectEstadoFormulario}
                    options={["ACTIVACION", "ACTIVAR", "ACTIVADO"]}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Estado Formulario"
                        name="estadoFormulario"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
