import React, { useState, useEffect } from "react"
import UsersContext from "../user/UserContext"
import { getUserPrincipal, logout } from "../user/UserService"

const defaultState = {
  idUsuario: 0,
  idTipoUsuario: 0,
  tipoUsuario: "",
  nombre: "",
  apellido: "",
  urlFoto: "",
  iat: 0,
  exp: 0,
}

const UserProvider = ({ children }) => {
  const [state, setState] = useState(defaultState)

  useEffect(() => {
    updateUser()
  }, [])

  const updateUser = async () => {
    let user = getUserPrincipal()
    if (user) {
      setState({
        idUsuario: user?.idUsuario,
        nombre: user?.nombre,
        apellido: user?.apellido,
        urlFoto: user?.urlFoto,
        iat: user?.iat,
        exp: user?.exp,
      })
    } else {
      setState(defaultState)
    }
  }

  const clearUser = () => {
    setState(defaultState)
    logout()
  }

  return (
    <UsersContext.Provider value={{ state, updateUser, clearUser }}>
      {children}
    </UsersContext.Provider>
  )
}

export default UserProvider
