import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import UserContext from "../../utils/user/UserContext"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import BackdropCustom from "../../components/BackdropCustom"

const inicialValue = {
  idBanner: 0,
  nombre: "",
  orden: 0,
  fechaDesde: "",
  fechaHasta: "",
}

export default function EditarBanner() {
  const history = useHistory()
  const classes = useStyles()
  const dataProps = history.location.state

  const [isLoading, setIsLoading] = useState(false)
  const [banner, setBanner] = useState(inicialValue)
  const [fotoPerfil, setFotoPerfil] = useState({})
  const [fotoPrinc, setFotoPrinc] = useState({})

  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.idBanner > 0) {
      let fechaDesde = ""
      let fechaHasta = ""

      if (dataProps.fechaDesde) {
        let fechaOld = dataProps.fechaDesde.split("T")
        let fechaNew = fechaOld[0]

        let fecha = fechaNew.split("-")
        console.log(fecha)
        let dia = fecha[0]
        let mes = fecha[1]
        let anho = fecha[2]
        fechaDesde = anho + "-" + (mes < 0 ? mes + 1 : mes) + "-" + dia
      }

      if (dataProps.fechaHasta) {
        let fechaOld = dataProps.fechaHasta.split("T")
        let fechaNew = fechaOld[0]

        let fechaFin = fechaNew.split("-")
        let diaFin = fechaFin[0]
        let mesFin = fechaFin[1]
        let anhoFin = fechaFin[2]
        fechaHasta =
          anhoFin + "-" + (mesFin < 0 ? mesFin + 1 : mesFin) + "-" + diaFin
      }

      console.log(fechaDesde)
      console.log(fechaHasta)

      let copyInput = {
        ...banner,
        idBanner: dataProps?.idBanner,
        nombre: dataProps?.descripcion,
        orden: dataProps?.orden,
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
      }
      setBanner(copyInput)
      setFotoPrinc(dataProps?.urlFoto)
    }
  }, [])

  const uploaderProps = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader()
      console.log(file)

      const duplicado = fotoPerfil?.file?.uid === file.uid

      console.log(duplicado)
      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file)
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {
          let base64 = reader.result.split(";base64,")
          let documentoBase64 = base64[1]
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          }

          console.log(fotoPerfil)
          setFotoPerfil(imagen)
        }

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          console.log("couldn't read the file")
        }
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      console.log("onError", err)
    },
    capture: "josue",
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "banner/banner-actualizar"
    try {
      const response = await axios.post(url, banner)
      let status = response.status
      if (status === 200) {
        if (Object.keys(fotoPerfil).length === 0) {
          setIsLoading(false)
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.goBack()
        } else {
          handleEnviarFotoPerfil(banner.idBanner)
        }
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const handleEnviarFotoPerfil = async (props) => {
    const formData = new FormData()
    formData.append("imgs", fotoPerfil?.file)
    try {
      const response = await axios.post(`banner/foto-banner/${props}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...banner, nombre: event.target.value }
    setBanner(copyInput)
  }

  const handleChangeOrden = (event) => {
    let copyInput = { ...banner, orden: Number(event.target.value) }
    setBanner(copyInput)
  }

  const handleChangeFechaDesde = (event) => {
    console.log(event.target.value)
    let copyInput = { ...banner, fechaDesde: event.target.value }
    setBanner(copyInput)
  }

  const handleChangeFechaHasta = (event) => {
    let copyInput = { ...banner, fechaHasta: event.target.value }
    setBanner(copyInput)
  }

  const handleEliminarImagen = (event) => {
    event.preventDefault()
    setFotoPerfil({})
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={5}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              {fotoPrinc === null ? (
                Object.keys(fotoPerfil).length === 0 ? (
                  <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                    <Avatar alt="Foto Banner" style={{ width: 100, height: 100 }}>
                      <PhotoCameraIcon fontSize="large" />
                    </Avatar>
                  </Box>
                ) : (
                  <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <Tooltip title="Eliminar imagen" aria-label="eleminar">
                          <IconButton
                            edge="end"
                            variant="contained"
                            aria-label="delete"
                            onClick={(event) => handleEliminarImagen(event)}
                          >
                            <DeleteIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      }
                    >
                      <Avatar
                        variant="rounded"
                        alt="Foto Banner"
                        src={`data:${fotoPerfil?.type};base64,${fotoPerfil?.data}`}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Badge>
                  </Box>
                )
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => setFotoPrinc(null)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      variant="rounded"
                      alt="Foto categoría"
                      src={fotoPrinc}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Badge>
                </Box>
              )}

              <Upload {...uploaderProps}>
                <Button
                  size="small"
                  //  startIcon={<PhotoCameraIcon />}
                  //startIcon={PublishIcon}
                  variant="contained"
                  color="primary"
                  children="Foto de Banner"
                />
              </Upload>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={banner.nombre}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={banner.orden}
                    onChange={(value) => handleChangeOrden(value)}
                    variant="outlined"
                    id="Orden"
                    name="Orden"
                    label="Orden"
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={banner.fechaDesde}
                    onChange={(value) => handleChangeFechaDesde(value)}
                    variant="outlined"
                    id="FechaDesde"
                    name="FechaDesde"
                    label="Fecha Desde"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={banner.fechaHasta}
                    onChange={(value) => handleChangeFechaHasta(value)}
                    variant="outlined"
                    id="FechaHasta"
                    name="FechaHasta"
                    label="Fecha Hasta"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
