import React, { useState, memo, useContext } from "react"
import clsx from "clsx"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import { useHistory } from "react-router-dom"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import InputAdornment from "@material-ui/core/InputAdornment"
import Drawer from "@material-ui/core/Drawer"
import Chip from "@material-ui/core/Chip"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications"
import logo from "../assets/images/logo.png"
import AvatarIcon from "../assets/images/avatar.png"
import Avatar from "@material-ui/core/Avatar"
import Exit from "@material-ui/icons/ExitToApp"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import GroupIcon from "@material-ui/icons/Group"
import SearchIcon from "@material-ui/icons/Search"
import UserContext from "../utils/user/UserContext"
import MenuOpenIcon from "@material-ui/icons/MenuOpen"
import AssistantPhotoIcon from "@material-ui/icons/AssistantPhoto"
import StorefrontIcon from "@material-ui/icons/Storefront"
import LocalMallIcon from "@material-ui/icons/LocalMall"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import PeopleIcon from "@material-ui/icons/People"
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import DescriptionIcon from "@material-ui/icons/Description"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import DataUsageIcon from "@material-ui/icons/DataUsage"

import ListIcon from "@material-ui/icons/List"
import FavoriteIcon from "@material-ui/icons/Favorite"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import MotorcycleIcon from "@material-ui/icons/Motorcycle"
import SendIcon from "@material-ui/icons/Send"
import Collapse from "@material-ui/core/Collapse"
import ExpandMore from "@material-ui/icons/ExpandMore"
import StarBorder from "@material-ui/icons/StarBorder"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import ExpandLess from "@material-ui/icons/ExpandLess"
import { containerCSS } from "react-select/dist/index-ea9e225d.cjs.prod"
import HistoryIcon from "@material-ui/icons/History"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import SettingsIcon from "@material-ui/icons/Settings"
import PaymentIcon from "@material-ui/icons/Payment"
import CategoryIcon from "@material-ui/icons/Category"
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import DashboardIcon from "@material-ui/icons/Dashboard"
import TypographyBold from "./TypographyBold"
import BeenhereIcon from "@material-ui/icons/Beenhere"
import { Button, colors } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    //  background: "rgb(96, 235, 82)",
    //background: "linear-gradient(140deg,rgba(11, 190, 113, 1) 0%,rgb(255 ,234 , 234 ) 100%)",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 12px",
    ...theme.mixins.toolbar,
    backgroundColor: "#f09f54",
  },

  appBar: {
    backgroundColor: "#f09f54",
    color: "#ffffff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  active: {
    backgroundColor: "rgb(0 74 173 / 9%)",
  },

  activeModule: {
    backgroundColor: "rgb(240 159 84  / 30% )",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  agrupador: {
    margin: theme.spacing(2, 1, 1, 2),
    color: "#6b6e71",
  },
}))

const drawerWidth = 235

function NavBar() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const [open, setOpen] = useState(true)
  const [openProyecto, setOpenProyecto] = useState(false)
  const [openColaborador, setOpenColaborador] = useState(false)
  const [openVisitas, setOpenVisitas] = useState(false)
  const [openComercio, setOpenComerio] = useState(false)
  const [openConfiguracion, setOpenConfiguracion] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [auth] = useState(true)
  const openMenu = Boolean(anchorEl)
  const pathName = window.location.pathname.split("/")
  const classes = useStyles()

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleHistoryRouter = (route) => {
    history.push(route)

    window.scrollTo({ behavior: "smooth", left: 0, top: 0 })
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{
          boxShadow:
            "0px 1px 1px -1px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 4%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          {/*
            
            open && (
              <IconButton color="inherit" onClick={handleDrawerClose}>
                <MenuOpenIcon />
              </IconButton>
            
            */}
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}></ListItem>

          {auth && (
            <div>
              <List
                className={classes.root}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItem button onClick={handleMenu}>
                  <ListItemAvatar style={{ minWidth: 50 }}>
                    <Avatar alt="Cindy Baker" src={userContext.state.urlFoto} />
                  </ListItemAvatar>

                  <ListItemText primary={userContext.state.nombre} />
                </ListItem>
              </List>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={openMenu}
                onClose={handleClose}
              >
                <Divider />

                <MenuItem
                  onClick={(e) => {
                    userContext.clearUser()
                    history.push("/acceder")
                  }}
                >
                  <ListItemIcon onClick={() => handleClose()}>
                    <Exit color="secondary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar sesión" />
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img width={70} alt="Logo" src={logo} />
          <Chip
            variant="default"
            label="V1.0.0"
            size="small"
            style={{
              height: 15,
              fontSize: 8,
              marginLeft: 5,
              alignSelf: "end",
              marginBottom: 10,

              backgroundColor: "fff",
            }}
          />
        </div>
        <Divider />

        <>
          <List style={{ scrollbarColor: "#ffff", overflowY: "auto" }}>
            <ListItem
              button
              onClick={() => handleHistoryRouter("/")}
              className={pathName?.[1] === "" && classes.active}
            >
              <ListItemIcon style={{ minWidth: 30 }}>
                <DashboardIcon fontSize="small" style={{ color: "#f09f54" }} />
              </ListItemIcon>
              <ListItemText secondary="Dashboard" />
            </ListItem>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Gestionar Comercios
              </TypographyBold>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/comercios/nuevo-comercio/")}
                className={pathName?.[2] === "nuevo-comercio" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Comercio" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/comercios/lista-activado/")}
                className={pathName?.[2] === "lista-activado" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Comercios activos" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/comercios/lista-mantenimiento/")
                }
                className={pathName?.[2] === "lista-mantenimiento" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Comercios en mantenimiento" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/comercios/lista-activar/")}
                className={pathName?.[2] === "lista-activar" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Comercios para activar" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/comercios/lista-activacion/")}
                className={pathName?.[2] === "lista-activacion" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Comercios en activación" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/destacar-propietario/")}
                className={
                  pathName?.[2] === "destacar-propietario" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <BeenhereIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Destacar Propietarios" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Colaboradores Koki
              </TypographyBold>
              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/colaboradores/nuevo-colaborador/")
                }
                className={pathName?.[2] === "nuevo-colaborador" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Colaborador" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/colaboradores/lista-colaboradores/")
                }
                className={pathName?.[2] === "lista-colaboradores" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Colaboradores" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/colaboradores/nuevo-tipo-colaborador/")
                }
                className={
                  pathName?.[2] === "nuevo-tipo-colaborador" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Tipo Colaborador" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/colaboradores/lista-tipo-colaborador/")
                }
                className={
                  pathName?.[2] === "lista-tipo-colaborador" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Tipo Colaborador" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Gestionar Pedidos
              </TypographyBold>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-pedidos/lista-pedidos/")
                }
                className={pathName?.[2] === "lista-pedidos" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar pedidos" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Gestionar Usuarios
              </TypographyBold>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-usuarios/lista-usuarios/")
                }
                className={pathName?.[2] === "lista-usuarios" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar usuarios" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Gestionar Banners
              </TypographyBold>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-banners/nuevo-banners/")
                }
                className={pathName?.[2] === "nuevo-banners" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Banner" />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-banners/lista-banners/")
                }
                className={pathName?.[2] === "lista-banners" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Banners" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Gestionar Ofertas
              </TypographyBold>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-ofertas/nueva-oferta/")
                }
                className={pathName?.[2] === "nueva-oferta" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Oferta" />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-ofertas/lista-ofertas/")
                }
                className={pathName?.[2] === "lista-ofertas" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar ofertas" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-ofertas/nuevo-mercado/")
                }
                className={pathName?.[2] === "nuevo-mercado" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Mercado" />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-ofertas/lista-mercado/")
                }
                className={pathName?.[2] === "lista-mercado" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar mercado" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Gestionar Motivo Cancelación
              </TypographyBold>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-motivo/nuevo-motivo-usuario/")
                }
                className={
                  pathName?.[2] === "nuevo-motivo-usuario" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Motivo por Usuario" />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-motivo/lista-motivo-usuario/")
                }
                className={
                  pathName?.[2] === "lista-motivo-usuario" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Motivo por Usuario" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-motivo/nuevo-motivo-comercio/")
                }
                className={
                  pathName?.[2] === "nuevo-motivo-comercio" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Motivo por Comercio" />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-motivo/lista-motivo-comercio/")
                }
                className={
                  pathName?.[2] === "lista-motivo-comercio" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Motivo por Comercio" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Gestión de Vendedores
              </TypographyBold>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-visitas/nueva-visita/")
                }
                className={pathName?.[2] === "nueva-visita" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Visita" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-visitas/lista-visitas/")
                }
                className={pathName?.[2] === "lista-visitas" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Visitas" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/gestionar-visitas/clientes/")}
                className={pathName?.[2] === "clientes" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <HistoryIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Historicos de visitas" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/gestionar-cartera/")}
                className={pathName?.[1] === "gestionar-cartera" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AssignmentIndIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Gestionar cartera" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Gestión de tramos
              </TypographyBold>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/gestionar-tramos/punteros/")}
                className={pathName?.[2] === "nueva-visita" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear tramos punteros" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/gestionar-tramos/vendedores/")}
                className={pathName?.[2] === "lista-visitas" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear tramos vendedores" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/gestionar-tramos")}
                className={pathName === "gestionar-tramos" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <HistoryIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Historicos de tramos" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Punto de Interes
              </TypographyBold>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/gestionar-recorrido/nuevo-recorrido/")
                }
                className={pathName?.[2] === "nuevo-recorrido" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Crear Recorrido" />
              </ListItem>
              {/* 
              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/listar-Familia/")
                }
                className={
                  pathName?.[2] === "lista-puntos-interes" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Familia" />
              </ListItem> */}

              {/* <ListItem
                button
                onClick={() => handleHistoryRouter("/gestionar-visitas/clientes/")}
                className={pathName?.[2] === "clientes" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <HistoryIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Historicos de visitas" />
              </ListItem> */}

              <ListItem
                button
                onClick={() => handleHistoryRouter("/istar-Familia/")}
                className={pathName?.[1] === "nueva-tipo" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Familia " />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/istar-Tipo/")}
                className={pathName?.[1] === "nueva-familia" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar tipo " />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/listar-SubTipo/")}
                className={pathName?.[1] === "listar-SubTipo" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Subtipo " />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/listar-Grupo-Informacion/")}
                className={
                  pathName?.[1] === "listar-Grupo-Informacion" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <ListIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Grupo de Informacion " />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/nuevo-grupo-informacion/")}
                className={
                  pathName?.[1] === "nuevo-grupo-informacion" && classes.active
                }
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AddCircleIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Nuevo Grupo de Informacion" />
              </ListItem>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/listar-Puntos-de-Interes/")}
                className={pathName?.[1] === "gestionar-cartera" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <AssignmentIndIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Listar Punto de Interes" />
              </ListItem>
            </List>

            <List component="div" disablePadding>
              <TypographyBold variant="subtitle2" className={classes.agrupador}>
                Configuración
              </TypographyBold>

              <ListItem
                button
                onClick={() => handleHistoryRouter("/categoria/lista-categorias/")}
                className={pathName?.[2] === "lista-categorias" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <CategoryIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Categorias" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/forma-de-pago/lista-forma-de-pago/")
                }
                className={pathName?.[2] === "forma-de-pago" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <PaymentIcon fontSize="small" style={{ color: "#f09f54" }} />
                </ListItemIcon>
                <ListItemText secondary="Formas de pago" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleHistoryRouter("/tipo-pedido/lista-tipo-pedido/")
                }
                className={pathName?.[2] === "tipo-pedido" && classes.active}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <PlaylistAddCheckIcon
                    fontSize="small"
                    style={{ color: "#f09f54" }}
                  />
                </ListItemIcon>
                <ListItemText secondary="Tipo Pedido" />
              </ListItem>
            </List>
          </List>
          <Divider />

          <MenuItem
            onClick={(e) => {
              userContext.clearUser()
              handleHistoryRouter("/acceder")
            }}
          >
            <ListItemIcon onClick={() => handleClose()}>
              <Exit color="secondary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" />
          </MenuItem>
        </>
      </Drawer>
    </>
  )
}

export default memo(NavBar)
