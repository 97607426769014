import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useTheme } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/core/styles"

import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import { red, green, blue } from "@material-ui/core/colors"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Chip from "@material-ui/core/Chip"
import { useHistory } from "react-router-dom"
import AssignmentIcon from "@material-ui/icons/Assignment"
import TypographyBold from "../../components/TypographyBold"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Divider from "@material-ui/core/Divider"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import Alert from "@material-ui/lab/Alert"
import BackdropCustom from "../../components/BackdropCustom"
import { alertWarningError } from "../../components/Notificaciones"

import axios from "../../utils/axios"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        INVESDE
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default function DetallePedido() {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const dataProps = history.location.state
  const [cancelar, setCancelar] = useState({})
  const [open, setOpen] = useState(false)
  const [anulacion, setAnulacion] = useState({})
  const [isLoadingAnulacion, setIsLoadingAnulacion] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pedido, setPedido] = useState({ cabecera: {}, detalle: [] })

  useEffect(() => {
    console.log(dataProps)
    if (dataProps?.id_orden) {
      getDetalle(dataProps?.id_orden)
    }
  }, [])
  const getDetalle = async (props) => {
    setIsLoading(true)
    let url = "pedidos/pedido-detalle"
    try {
      const response = await axios.post(url, {
        idOrden: props,
      })
      let status = response.status
      if (status === 200) {
        const pedido = response.data
        setPedido({
          ...pedido,
          cabecera: pedido?.result?.cabecera,
          detalle: pedido?.result?.detalle,
        })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCancelarPedido = (props) => {
    setOpen(true)
    setCancelar(props)
    console.log(props)
  }

  const onSelectAnulacion = (e, value) => {
    if (value && value !== anulacion) {
      setAnulacion(value)
    }

    if (value === null) {
      setAnulacion({})
    }
  }
  function Cancelar(props) {
    const { open, onClose, data } = props

    console.log(data)

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          RAZON DE ANULACION
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box p={1}>
            <Autocomplete
              id="motivo"
              size="small"
              value={anulacion || ""}
              onChange={onSelectAnulacion}
              options={["RECHAZO POR CLIENTE", "ERROR CARGA", "PRUEBA SOPORTE"]}
              getOptionLabel={(option) => (option ? option : "")}
              renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
              loading={isLoadingAnulacion}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleccione el Motivo"
                  name="motivo"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingAnulacion ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="secondary"
                fullWidth
                variant="contained"
                onClick={() => onClose()}
              >
                Aplicar anulación
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  function CardFactura(props) {
    return (
      <>
        <Card>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                <AssignmentIcon />
              </Avatar>
            }
            title={
              pedido.cabecera?.nombre &&
              pedido.cabecera?.nombre + " " + pedido.cabecera?.apellido
            }
            subheader={
              <Chip
                label={"# Orden: " + pedido.cabecera?.idOrden}
                size="small"
                color="secondary"
              />
            }
          />

          <CardContent style={{ padding: 5 }}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box p={1}>
                  <TypographyBold
                    variant="body2"
                    display="block"
                    color="textSecondary"
                  >
                    Datos de factuación
                  </TypographyBold>
                </Box>
                <Box p={1}>
                  <Grid container justify="center" alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Nombre y Apellido
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {pedido.cabecera?.nombre &&
                          pedido.cabecera?.nombre + " " + pedido.cabecera?.apellido}
                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        CONTACTO
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {pedido.cabecera?.celular && pedido.cabecera?.celular}
                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Razón Social
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {pedido.cabecera?.razonSocial &&
                          pedido.cabecera?.razonSocial}
                      </TypographyBold>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        RUC
                      </Typography>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {pedido.cabecera?.ruc && pedido.cabecera?.ruc}
                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Comentario
                      </TypographyBold>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {pedido.cabecera?.observacion &&
                          pedido.cabecera?.observacion}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Vuelto de:
                      </TypographyBold>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        {pedido.cabecera?.vueltoDe
                          ? " GS." +
                            parseFloat(pedido.cabecera?.vueltoDe).toLocaleString(
                              "es",
                            )
                          : "Sin Vuelto"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Alert
                        icon={false}
                        variant="outlined"
                        severity="error"
                        style={{
                          justifyContent: "center",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        {pedido.cabecera?.tipoPago && pedido.cabecera?.tipoPago}
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Alert
                        icon={false}
                        variant="outlined"
                        severity="success"
                        style={{
                          justifyContent: "center",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        {pedido.cabecera?.tipoPedido && pedido.cabecera?.tipoPedido}
                      </Alert>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box p={1}>
                  <Grid container justify="center" alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        DELIVERY
                      </TypographyBold>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Alert
                        icon={false}
                        variant="standard"
                        severity="success"
                        style={{
                          justifyContent: "center",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        GS.{" "}
                        {pedido.cabecera?.delivery &&
                          parseFloat(pedido.cabecera?.delivery).toLocaleString("es")}
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        TOTAL PEDIDO
                      </TypographyBold>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Alert
                        icon={false}
                        variant="filled"
                        severity="success"
                        style={{
                          justifyContent: "center",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        GS.{" "}
                        {pedido.cabecera?.total &&
                          parseFloat(pedido.cabecera?.total).toLocaleString("es")}
                      </Alert>
                    </Grid>
                    {pedido.detalle.map((value) => {
                      return (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Card>
                            <CardHeader
                              style={{ padding: 2 }}
                              avatar={
                                <Avatar
                                  aria-label="recipe"
                                  style={{ width: 190, height: 190, padding: 10 }}
                                  variant="rounded"
                                  alt={value?.menu}
                                  src={value?.urlFoto}
                                />
                              }
                              title={value?.menu}
                              subheader={
                                <>
                                  <Alert
                                    icon={false}
                                    variant="standard"
                                    severity="error"
                                    style={{
                                      justifyContent: "center",
                                      padding: "0px 5px 0px 5px",
                                      marginTop: 15,
                                      width: "40%",
                                    }}
                                  >
                                    {"GS." +
                                      parseFloat(value?.precio).toLocaleString("es")}
                                  </Alert>
                                  <Alert
                                    icon={false}
                                    variant="outlined"
                                    severity="success"
                                    style={{
                                      justifyContent: "center",
                                      padding: "0px 5px 0px 5px",
                                      marginTop: 15,
                                      width: "40%",
                                    }}
                                  >
                                    Cant:{" "}
                                    {parseFloat(value?.cant).toLocaleString("es")}
                                  </Alert>
                                  <Alert
                                    icon={false}
                                    variant="standard"
                                    severity="success"
                                    style={{
                                      justifyContent: "center",
                                      padding: "0px 10px 0px 10px",
                                      marginTop: 15,
                                    }}
                                  >
                                    TOTAL: GS.{" "}
                                    {parseFloat(value?.total).toLocaleString("es")}
                                  </Alert>
                                </>
                              }
                            />
                          </Card>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignContent="center"
              spacing={2}
            >
              <Grid item>
                <BotonGris
                  size="small"
                  color="default"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Salir
                </BotonGris>{" "}
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </>
    )
  }
  return (
    <>
      <BackdropCustom open={isLoading} />

      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 2 }}>
          <CardFactura estado={dataProps?.id} />
        </Grid>
      </Grid>

      <Box pb={2} pt={6} className={classes.footerBotttom}>
        <Copyright />
      </Box>

      {open && <Cancelar open={open} onClose={handleClose} data={cancelar} />}
    </>
  )
}
